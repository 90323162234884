import React, { useEffect, useRef } from "react";
import { Image, mergeStyles, hiddenContentStyle } from "@fluentui/react";

import { ProgressIndicator } from "./ProgressIndicator";

import "../styles/IntroDialog.scss";
import { useTeamsFx } from "@microsoft/teamsfx-react";

/**
 * IntroDialogStep1 component
 */
interface IComponentProps {
  stepNumber: number;
}

export const IntroDialogStep1: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { stepNumber } = props;

  const { themeString } = useTeamsFx();  
  const blIntroStep1 = require("../../assets/images/blIntroStep1.png");
  const blIntroStep1Dark = require("../../assets/images/blIntroStep1Dark.png");
  const imageSource = (themeString === 'dark') ? blIntroStep1Dark : blIntroStep1;

  const textToDisplay = "Welcome";
  const screenReaderOnly = mergeStyles(hiddenContentStyle);

  const dialogReference = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    if (dialogReference.current != null) {
      dialogReference.current.focus();
    }
  }, []);

  return (
    <>
      <label id="dialogLabelCongrats" className={screenReaderOnly}>
        {textToDisplay}
      </label>
      <div className="contentWrapper">
        <div className="content">
          <div className="dialogContentLeft">
            <ProgressIndicator stepNumber={stepNumber} />
            <h2 className="ms-fontWeight-bold" ref={dialogReference}>
              {textToDisplay}
            </h2>
            <div className="message">
              Welcome! This course offers live,
              on-demand instructor-led training and self-directed modules.
            </div>
            <div className="message">
              Our new blended learning format is flexible, so you can complete
              the course in the best way for your schedule. You can move through
              the content by week, stay on pace with the live sessions, or move
              ahead by completing any of the self-directed topics.
            </div>
          </div>
          <div className="dialogContentRight">
            <Image
              src={imageSource}
              alt=""
              className="imageWrapper"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroDialogStep1;