import { Link } from "@fluentui/react-components";
import { WindowNew16Regular } from "@fluentui/react-icons";
import React from "react";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { LearnerSyllabusTask } from "../../models";

interface Props {
  task: LearnerSyllabusTask
  deliveryLevelUrl: boolean;
  includeMSLearnSSOParams: boolean;
  setTaskCompletion: (task: LearnerSyllabusTask) => void;
}

const LaunchLink: React.FC<Props> = ({
  task,
  deliveryLevelUrl,
  includeMSLearnSSOParams = false,
  setTaskCompletion,
}) => {
  let launchUrl = deliveryLevelUrl ? task.taskURL : task.syllabusTask.platformTaskURL;
  const linkText = task.syllabusTask.name;

  if (launchUrl === null) {
    ai.appInsights?.trackException({ exception: new Error("LaunchLink URL is null")}, 
      { "component": "LaunchLink", "launchUrl": launchUrl, "linkText": linkText, "includeMSLearnSSOParams": includeMSLearnSSOParams});
  }
  
  if (includeMSLearnSSOParams && launchUrl !== null) {
    const targetUrl = new URL(launchUrl);
    const params = targetUrl.searchParams;
    params.set("wt.mc_id", "esi_blended_content");
    params.set("sso", "blended-learning");
    launchUrl = targetUrl.toString();
  }

  const handleClick = () => {
    setTaskCompletion(task);
    window.open(launchUrl, "_blank");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setTaskCompletion(task);
      window.open(launchUrl, "_blank");
    }
  };

  return (
    <Link aria-label={linkText} onClick={handleClick} as="a" onKeyDown={handleKeyDown}>
      {linkText} <WindowNew16Regular />
    </Link>
  );
};

export default LaunchLink;
