import {
  makeStyles,
  Body1,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Text,
} from "@fluentui/react-components";
import * as React from "react";
import CustomIcon from "./CustomIcon";
import JoinSessionButton from "./JoinSessionButton";
import { useQuery } from "@tanstack/react-query";
import { BlendedLearningContext } from "../../internal/singletonContext";
import SessionTimer from "./SessionTimer";
import { LearnerSyllabusTask } from "../../models/learnerSyllabusTask";
import { SendInviteDialog } from "./SendInviteDialog";
import { convertToLocalTimeZone } from "../../common/dateUtils";

/**
 * LiveSessionCard component
 */
interface IComponentProps {
  item: LearnerSyllabusTask;
}

const useStyles = makeStyles({
  card: {
    width: "24%",
    minWidth: "20.5em",
    minHeight: "23em",
  },
  headerWrapper: {
    display: "flex",
  },
  header: {
    display: "flex",
    fontWeight: "600",
  },
  content: {
    fontSize: "19px",
    fontWeight: "600",
    position: "relative",
    top: "0.5em",
    left: "1em",
    height: "2em",
  },
  timeContainer: {
    position: "relative",
    left: "1.5em",
    top: "1.5em",
  },
  taskNameContainer: {
    fontWeight: "600",
    fontSize: "16px",
    display: "block",
    width: "100%",
    marginBottom: "1em",
  },
  grayText: {
    color: "#686868",
    fontSize: "12px",
  },
  note: {
    position: "relative",
    top: "0.5em",
    left: "1em",
    maxWidth: "90%",
    flexWrap: "wrap",
    height: "1.5em",
    color: "#686868",
  },
  timer: {
    position: "absolute",
    right: "1.5em",
  },
  footer: {
    width: "90%",
    position: "absolute",
    bottom: "1.7em",
    left: "1.7em",
  },
});

export const LiveSessionCard: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { item } = props;

  const styles = useStyles();

  const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
    queryKey: ["BlxpData"],
    queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
  });

  const taskStartDateTime = convertToLocalTimeZone(new Date(
    Date.parse(item.taskStartDateTime))
  ).getTime();
  const endTime = convertToLocalTimeZone(new Date(Date.parse(item.taskEndDateTime))).getTime();

  const durationHour = Math.floor((endTime - taskStartDateTime) / 60 / 60 / 1000);
  const durationMinute = ((endTime - taskStartDateTime) / 60 / 1000) - (durationHour * 60);

  const remainingTimeToStart = (taskStartDateTime - Date.now()) / 60000;
  const isUpcomingSession =
    remainingTimeToStart > 0 && remainingTimeToStart < 30;
  const isPastDue = endTime < Date.now();

  const isSessionProgress = (item: LearnerSyllabusTask) => {
    const endTime = convertToLocalTimeZone(new Date(Date.parse(item.taskEndDateTime))).getTime();
    const startTime = convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).getTime();
    const currentTime = Date.now();
    return endTime > currentTime && currentTime > startTime;
  };
  const handleChange = () => {
    console.log("Live Sessions Send Invite handleChange");
    // Helper.setClarityCustomTag('Syllabus table', 'SendInviteClicked');
    // ai.appInsights?.trackEvent({
    //   name: "Syllabus table: Send invite for task" + { item },
    // });
    // resendSessions.mutate(payload);
  };

  return (
    <Card className={styles.card}>
      <CardHeader
        image={
          <CustomIcon iconName={item.syllabusTask.syllabusTaskType.name} />
        }
        header={
          <Body1 className={styles.headerWrapper}>
            <Text className={styles.header}>
              {item.syllabusTask.syllabusTaskType.name}
            </Text>{" "}
            {isUpcomingSession && (
              <span className={styles.timer}>
                <SessionTimer
                  timerDurationInMinutes={
                    (convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).getTime() - Date.now()) /
                    60 /
                    1000
                  }
                />
              </span>
            )}
          </Body1>
        }
      />

      <CardPreview>
        {isPastDue && (
          <>
            <img
              src={require("../../assets/images/MicrosoftTeams.png")}
              alt="Live session"
            />
            <div>
              <span className={styles.note}>This event occurred on: </span>
              <br />
              <span className={styles.note}>
                {convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).toLocaleString(
                  "en-us",
                  {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )}
              </span>
            </div>
          </>
        )}
        {!isPastDue && (
          <div>
            <Text className={styles.content}>
              {convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).toLocaleString(
                "en-us",
                {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                }
              )}
            </Text>
            <span className={styles.timeContainer}>
              <div>
                {convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).toLocaleString(
                  "en-us",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                )}
              </div>
              <div className={styles.grayText}>
                {durationHour == 1 ? durationHour : null} {durationHour == 1 ? "hour" : null}
                {durationHour > 1 ? durationHour : null} {durationHour > 1 ? "hours" : null}
                {durationMinute > 0 ? durationMinute : null} {durationMinute > 0 ? "minutes" : null}
                </div>
            </span>
          </div>
        )}
      </CardPreview>

      <CardFooter className={styles.footer}>
        {!isPastDue && (
          <div>
            <Text className={styles.taskNameContainer}>
              {item.syllabusTask.name}
            </Text>
            {!isUpcomingSession && !isSessionProgress(item) ? (
              <SendInviteDialog
                task={item}
                emailAddress={blxpData?.learner?.email}
                locale="en-US"
              />
            ) : (
              <JoinSessionButton item={item} />
            )}
          </div>
        )}
      </CardFooter>
    </Card>
  );
};
