import { tokens } from "@fluentui/react-components";
import { mergeStyleSets } from "@fluentui/react";

export const liveSessionWidgetStyle = mergeStyleSets({
  root: {
    display: "grid",
    padding: "0.75rem 1.25rem 1rem 1.25rem",
    backgroundColor: tokens.colorNeutralBackground1,
    borderWidth: "0.25em",
    borderStyle: "solid",
    borderColor: tokens.colorTransparentStroke,
    boxShadow: tokens.shadow4,
    borderRadius: tokens.borderRadiusMedium,
    gap: "1rem",
    gridTemplateRows: "max-content 1fr max-content",
  },
  header: {
    wrap: "wrap",
    height: "max-content",
  },
  headerWithoutIcon: {
    display: "grid",
    gridTemplateColumns: "1fr min-content",
    alignItems: "center",
  },
  headerIcon: {
    marginLeft: "11em",
    marginTop: "-1.5em",
  },
  headerContent: {
    display: "grid",
    gap: "2em",
    gridTemplateColumns: "min-content 1fr min-content",
    alignItems: "left",
  },
  headerText: {
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    fontSize: "14px !important",
  },
  bodyDividerCell: {
    width: "0.25em",
    height: "80%",
    backgroundColor: tokens.colorNeutralForeground2BrandHover,
    borderRadius: "0.125em",
  },
  bodyPopupSurface: {
    display: "flex !important",
    height: "40em",
    overflow: "hidden !important",
    padding: "2em !important",
  },
  bodyTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    fontColor: tokens.colorNeutralForegroundStaticInverted,
  },
  bodyPopupBody: {
    width: "40em",
    height: "30em",
    fontColor: tokens.colorNeutralForeground2,
  },
  bodyPopupTitle: {
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    padding: "0 20em 0 0",
    height: "0.5em",
    fontColor: tokens.colorNeutralForegroundStaticInverted,
  },
  bodyPopupContent: {
    backgroundColor: "#F3F6F8",
    margin: "4em 0em -5em -32em !important",
  },
  bodyDate: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: tokens.colorNeutralForeground2,
  },
  bodyTime: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    color: tokens.colorNeutralForeground2,
  },
  bodyNoMoreSessions: {
    height: "10em",
    marginTop: "7.5em",
  },
  bodyNoMoreSessionsText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    marginLeft: "22.5em",
  },
  bodySearchBox: {
    width: "12em",
    margin: "-0.5em 2.5em 0em 22em",
  },
  bodyInvitationStatus: {
    fontFamily: "Segoe UI",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
  },
  bodyNotSentStatus: {
    color: tokens.colorStatusWarningForeground1,
  },
  bodySentStatus: {
    color: tokens.colorPaletteLightGreenForeground1,
  },
  footerBtn: {
    width: "fit-content",
    color: "#0078D4 !important",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
  },
  teamsButton: {
    backgroundColor: tokens.colorNeutralForeground2BrandHover,
  },
});
