import { shorthands, tokens } from "@fluentui/react-components";
import { mergeStyleSets } from "@fluentui/react";
import clsx from "clsx";

export const widgetStyle = mergeStyleSets({
  root: {
    display: "grid",
    padding: "0.75rem 1.25rem 1rem 1.25rem",
    backgroundColor: tokens.colorNeutralBackground1,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: tokens.colorTransparentStroke,
    boxShadow: tokens.shadow4,
    borderRadius: tokens.borderRadiusMedium,
    gap: "1rem",
    gridTemplateRows: "max-content 1fr max-content",
  },
  header: {
    display: "grid",
    alignItems: "center",
    height: "max-content",
  },
  headerWithoutIcon: {
    display: "grid",
    gap: "8px",
    gridTemplateColumns: "1fr min-content",
    alignItems: "center",
  },
  headerContent: {
    display: "grid",
    gap: "8px",
    gridTemplateColumns: "min-content 1fr min-content",
    alignItems: "center",
  },
  headerText: {
    fontWeight: "600 !important",
    lineHeight: "1rem !important",
    fontSize: "1rem !important",
    color: tokens.colorNeutralForeground1,
  },
  footerBtn: {
    width: "fit-content",
    color: clsx(tokens.colorCompoundBrandForeground1Hover, "!important"),
    paddingLeft: "0.25rem",
    paddingRight: "0"
  },
  linkStyle: {
    color: clsx(tokens.colorCompoundBrandForeground1Hover, "!important"),
  },
  currentTopicButton:{
    minWidth:"3.1em !important",
    verticalAlign: "initial !important",
    ...shorthands.padding(".05em !important"),   
  },
  body1Style: {
    color: clsx(tokens.colorNeutralForeground2, "!important"),
    a: {
      color: tokens.colorCompoundBrandForeground1Hover,
    }
  },
  purpleIcon: {
    backgroundColor: tokens.colorBrandForeground1,
    borderRadius: "50%",
    color: "#fff",
    width: "2em",
    height: "2em",
    marginRight: "0.2em",
  },
  iconSmall: {
      position: "relative",
      top: "0.2em",
      left: "0.2em",
      rotate: "-6.68deg",
  },
});