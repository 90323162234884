import {
  makeStyles,
  Body1,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Text,
  Button,
  shorthands,
} from "@fluentui/react-components";
import * as React from "react";
import { LearnerSyllabusTask, mediaAccessResult } from "../../models";
import CustomIcon from "./CustomIcon";
import VideoDialog from "./VideoDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateLearnerSyllabusTaskCompletedState } from "../../services/syllabusService";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";

/**
 * OverviewVideoCard component
 */
interface IComponentProps {
  task: LearnerSyllabusTask;
  email: string;
}

const useStyles = makeStyles({
  cardPreview: {
    height: "52%",
  },
  card: {
    minHeight: "26em",
  },
  headerWrapper: {
    display: "flex",
  },
  header: {
    display: "flex",
    fontWeight: "600",
  },
  taskorder: {
    fontSize: "12px",
    marginRight: ".5em",
    marginLeft: ".5em",
    marginTop: ".1em",
  },
  taskname: {
    fontSize: "14px",
    marginLeft: ".5em",
  },
  content: {
    fontWeight: "600",
    display: "block",
    width: "95%",
    marginBottom: "1em",
  },
  tasktitle: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "1.5em",
    ...shorthands.overflow("hidden"),
    width: "280px",
    display: "block",
  },
  image: {
    height: "13em !important",
  },
  grayText: {
    color: "#333333",
    fontSize: "12px",
    lineHeight: "1.25em",
    fontWeight: "400",
  },
  footer: {
    position: "absolute",
    bottom: "1.5em",
    left: "1.1em",
    fontSize: "14px",
    fontWeight: "600",
  },
  watchBtn: {
    height: "2em",
  },
});

export const OverviewVideoCard: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { task, email } = props;
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = React.useState(false);
  const styles = useStyles();

  const getFormattedTaskOrderNumber = (item: LearnerSyllabusTask) => {
    return item.syllabusTask.taskOrderNumber.toString().replace(".", "-");
  };

  const onDismiss = () => {
    setIsOpen(false);
  };

  const onVideoWatched = () => {
    if (!task.isTaskCompleted) {
      onTaskComplted();
    }
  };

  const taskCompletionMutation = useMutation({
    mutationFn: async () => {
      await updateLearnerSyllabusTaskCompletedState(task.id, email);
    },
    onMutate: () => {
      task.isTaskCompleted = !task.isTaskCompleted;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["UserTasksData"]);
    },
    onSettled: () => {},
  });

  const onTaskComplted = async () => {
    ai.appInsights?.trackEvent({
      name: "Overviewvideo watched: Task is competed (button click).",
    });
    ClarityHelper.setCustomTag("CompleteTask", `${task.syllabusTask}`);
    await taskCompletionMutation.mutateAsync();
  };
  return (
    <Card className={styles.card}>
      <CardHeader
        header={
          <Body1 className={styles.headerWrapper}>
            <Text className={styles.header}>
              <span className={styles.taskorder}>
                {getFormattedTaskOrderNumber(task)}
              </span>
              <CustomIcon iconName={task.syllabusTask.syllabusTaskType.name} />
              <span className={styles.taskname}>
                {" "}
                {task.syllabusTask.syllabusTaskType.name}
              </span>
            </Text>{" "}
          </Body1>
        }
      />

      <CardPreview className={styles.cardPreview}>
        <img
          className={styles.image}
          src={require("../../assets/images/OverviewVideoDefaultImage.png")}
          alt="overview video"
        />
      </CardPreview>
      <div className={styles.content}>
        <Text truncate className={styles.tasktitle}>
          {task.syllabusTask.name}
        </Text>
        <Text className={styles.grayText}>
          {task.syllabusTask.durationInMinutes} min
        </Text>
      </div>
      <CardFooter className={styles.footer}>
        <VideoDialog
          isOpen={isOpen}
          onDismiss={onDismiss}
          task={task}
          onPlayVideo={onVideoWatched}
          showPlayButton={true}
        />
         <Button className={styles.watchBtn} disabled={task.isTaskCompleted} onClick={onVideoWatched} shape="rounded">{task.isTaskCompleted?"Video marked as watched":"Mark video as watched"}</Button>

      </CardFooter>
    </Card>
  );
};
