import { app } from "@microsoft/teams-js";

/** A function that will construct an endpoint Url */
export type EndpointFunction = (param: string) => string;
export type EndpointFunctionwithExtraParam = (paramfirst: string, paramsecond: string) => string;
export type EndpointFunctionwithThreeParam = (paramfirst: string, paramsecond: string, paramthird: string) => string;
export type EndpointFunctionWithOptionalParam = (param: string, oParam?: string) => string;
/** A configuration interface used to describe an API service and the collection of endpoints it contains */
export interface IServiceConfiguration {
  /** The base Url of the service */
  baseUri: Readonly<string>;

  /** The API key used to authenticate with the service */
  apiKey: Readonly<string>;

  /** A collection of endpoints existing on the service */
  endpoints: {
    [key: string]: Readonly<string | EndpointFunction  | EndpointFunctionwithExtraParam | EndpointFunctionwithThreeParam | EndpointFunctionWithOptionalParam>;
  };
}

export async function GetDefaultRequestConfig(service: IServiceConfiguration, useCacheBuster: boolean = true, teamName: string = '') {
  const defaultRequestConfig = {params:{}, headers:{}};
  if (useCacheBuster) {
    defaultRequestConfig.params = {
          cacheBuster: Date.now()
      };
  }

  defaultRequestConfig.headers = {
    'Ocp-Apim-Subscription-Key': service.apiKey,
    'Team-Name':await getTeamName()
  }; 

  return defaultRequestConfig;
}

async function getTeamName():Promise<string | undefined>{
  const teamcontext=await app.getContext();
  return teamcontext.team?.displayName;
}