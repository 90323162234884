import { Text, makeStyles } from "@fluentui/react-components";
import * as React from "react";

import { LearnerSyllabusTask } from "../../models";

import "../styles/Syllabus.scss";
import ItemDetails from "./ItemDetails";
import { convertToLocalTimeZone } from "../../common/dateUtils";

/**
 * MoreInfo component
 */
interface IComponentProps {
  item: LearnerSyllabusTask;
  onContinue: () => void;
}

const useStyles = makeStyles({
  moreInfoContainer: {
    display: "flex",
    flexWrap: "wrap",
    height: "2.5em",
    alignContent: "center",
  },
});

export const MoreInfo: React.FunctionComponent<IComponentProps> = (props) => {
  const { item, onContinue } = props;

  const styles = useStyles();

  const startTime = convertToLocalTimeZone(new Date(Date.parse(item.taskStartDateTime))).getTime();
  const endTime = convertToLocalTimeZone(new Date(Date.parse(item.taskEndDateTime))).getTime();

  const remainingTimeToStart = (startTime - Date.now()) / 60000;
  const isUpcomingSession =
    remainingTimeToStart > 0 && remainingTimeToStart < 30;

  const isPastDue = endTime < Date.now() ;

  return (
    <div className={styles.moreInfoContainer}>
      {isUpcomingSession && !isPastDue && (
        <ItemDetails
          item={item}
          isShowTimer={isUpcomingSession}
          onContinue={onContinue}
        />
      )}
      {!isUpcomingSession && !isPastDue && (
        <ItemDetails
          item={item}
          isShowTimer={!isUpcomingSession}
          onContinue={onContinue}
        />
      )}
      {isPastDue && (
        <Text>
          This session has concluded. Refer to the focus area channel.
        </Text>
      )}
    </div>
  );
};

export default MoreInfo;
