import React, { useEffect, useRef } from "react";
import { Image, mergeStyles, hiddenContentStyle } from "@fluentui/react";
import {
  Book24Regular,
  ClipboardTask24Regular,
  VideoClip24Regular,
} from "@fluentui/react-icons";

import { ProgressIndicator } from "./ProgressIndicator";

import "../styles/IntroDialog.scss";
import { useTeamsFx } from "@microsoft/teamsfx-react";

/**
 * IntroDialog2 component
 */
interface IComponentProps {
  stepNumber: number;
}

export const IntroDialogStep2: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { stepNumber } = props;

  const { themeString } = useTeamsFx();  
  const blIntroStep2 = require("../../assets/images/blIntroStep2.png");
  const blIntroStep2Dark = require("../../assets/images/blIntroStep2Dark.png");
  const imageSource = (themeString === 'dark') ? blIntroStep2Dark : blIntroStep2;

  const dialogReference = useRef<HTMLHeadingElement>(null);
  const screenReaderOnly = mergeStyles(hiddenContentStyle);

  useEffect(() => {
    if (dialogReference.current != null) {
      dialogReference.current.focus();
    }
  }, []);

  const textToDisplay = "What to Expect";

  return (
    <>
      <label id="dialogLabelCongrats" className={screenReaderOnly}>
        {textToDisplay}
      </label>
      <div className="contentWrapper">
        <div className="content">
          <div className="dialogContentLeft">
            <ProgressIndicator stepNumber={stepNumber} />
            <h2 className="ms-fontWeight-bold" ref={dialogReference}>
              {textToDisplay}
            </h2>
            <div className="message">
              You will experience self-directed and live modules during the
              course over four weeks
            </div>
            <div className="message">
              The self-directed sessions can be completed at your own pace and
              include:
            </div>
            <ul className="list ms-fontWeight-bold">
              <li className="listItem">
                <Book24Regular aria-label="Book icon" className="iconList" />
                <span>Self Study Modules</span>
              </li>
              <li className="listItem">
                <ClipboardTask24Regular
                  aria-label="Clipboard task icon"
                  className="iconList"
                />
                <span>Knowledge Checks</span>
              </li>
              <li className="listItem">
                <VideoClip24Regular
                  aria-label="Video clip icon"
                  className="iconList"
                />
                <span>Topic Videos</span>
              </li>
            </ul>
          </div>
          <div className="dialogContentRight">
            <Image
              src={imageSource}
              alt=""
              className="imageWrapper"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroDialogStep2;
