import { Text } from "@fluentui/react-components";
import React from "react";

interface Props {
    durationInMinutes: number;
  }
  
  const DurationTimeLabel: React.FC<Props> = ({
    durationInMinutes,
  }) => {
    const minutes = durationInMinutes % 60;
    const hours = (durationInMinutes - minutes) / 60;

    const minuteUnit = minutes === 1 ? "minute" : "minutes";
    const hourUnit = hours === 1 ? "hour" : "hours";


    return (
        <>
            {hours > 0 && <Text>{hours} {hourUnit}</Text>}
            {minutes > 0 && <Text> {minutes} {minuteUnit}</Text>}
        </>
    );
  };
  
  export default DurationTimeLabel;