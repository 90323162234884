import React, { useEffect, useRef } from "react";
import { Image, mergeStyles, hiddenContentStyle } from "@fluentui/react";

import { ProgressIndicator } from "./ProgressIndicator";

import "../styles/IntroDialog.scss";
import { useTeamsFx } from "@microsoft/teamsfx-react";

/**
 * IntroDialog4 component
 */
interface IComponentProps {
  stepNumber: number;
}

export const IntroDialogStep4: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { stepNumber } = props;
  const dialogReference = useRef<HTMLHeadingElement>(null);
  const screenReaderOnly = mergeStyles(hiddenContentStyle);
  const { themeString } = useTeamsFx();  
  const blIntroStep4 = require("../../assets/images/blIntroStep4.png");
  const blIntroStep4Dark = require("../../assets/images/blIntroStep4Dark.png");
  const imageSource = (themeString === 'dark') ? blIntroStep4Dark : blIntroStep4;

  useEffect(() => {
    if (dialogReference.current != null) {
      dialogReference.current.focus();
    }
  }, []);

  const textToDisplay = "Get Started!";

  return (
    <>
      <label id="dialogLabelCongrats" className={screenReaderOnly}>
        {textToDisplay}
      </label>
      <div className="contentWrapper">
        <div className="content">
          <div className="dialogContentLeft">
            <ProgressIndicator stepNumber={stepNumber} />
            <h2 className="ms-fontWeight-bold" ref={dialogReference}>
              {textToDisplay}
            </h2>
            <div className="message">
              Use the <b>Syllabus</b> below to track your progress through the
              course and plan which modules, labs, and sessions you want to
              attend. Get started!
            </div>
          </div>
          <div className="dialogContentRight">
            <Image
              src={imageSource}
              alt=""
              className="imageWrapper"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroDialogStep4;
