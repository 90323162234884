import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  Text,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  makeStyles,
  shorthands,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  useId,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";

import { LearnerSyllabusTask } from "../../models";

import "../styles/Syllabus.scss";
import TaskPopover from "./TaskPopover";

/**
 * YourProgressTasks component
 */
interface IComponentProps {
  task: LearnerSyllabusTask;
  learnerEmail: string;
}

const useStyles = makeStyles({
  taskWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop:"0.5em"
  },
  completedBox: {
    backgroundColor: tokens.colorBrandForeground1,
    ...shorthands.borderColor(tokens.colorBrandForeground1),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderRadius("5px"),
    width: "1em",
    height: "1em",
  },
  notCompletedBox: {
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderColor(tokens.colorBrandForeground1),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderRadius("5px"),
    width: "1em",
    height: "1em",
  },
  flaggedDot: {
    backgroundColor: "#E93A39",
    ...shorthands.borderRadius("50%"),
    width: "0.4em",
    height: "0.4em",
    marginTop:"0.25em"
  },
  notFlaggedDot: {
    ...shorthands.borderStyle("none"),
    width: "0.4em",
    height: "0.4em",
    marginTop:"0.25em"
  },
});

export const Task: React.FunctionComponent<IComponentProps> = (props) => {
  const { task, learnerEmail } = props;
  const id = useId();
  const classes = useStyles();

  return (
    <>
      <span className={classes.taskWrapper}>
        <Popover withArrow openOnHover trapFocus>
          <PopoverTrigger disableButtonEnhancement>
            <div
              role="button"
              aria-label={` Task ${task.syllabusTask.taskOrderNumber}`}
              className={`${
                task.isTaskCompleted
                  ? classes.completedBox
                  : classes.notCompletedBox
              }`}
            ></div>
          </PopoverTrigger>
          <PopoverSurface
            aria-labelledby={id}
            aria-label={` Task ${task.syllabusTask.taskOrderNumber}`}
          >
            <TaskPopover task={task} learnerEmail={learnerEmail} />
          </PopoverSurface>
        </Popover>
        <div
          className={`${
            task.isTaskFlagged ? classes.flaggedDot : classes.notFlaggedDot
          }`}
        ></div>
      </span>
    </>
  );
};

export default Task;
