import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Link, Spinner, makeStyles, shorthands } from '@fluentui/react-components';

import '../styles/SessionDialog.scss';
import { LiveSessionsTable } from './LiveSessionsTable';
import { LearnerSyllabusTask } from '../../models';
import { widgetStyle } from '../lib/Widget.styles';

import '../styles/SessionDialog.scss';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { ai } from '../../internal/ApplicationInsightsProvider/ApplicationInsightsService';
import { ClarityHelper } from '../../internal/ClarityHelper';
import { ILiveSessionStatus } from '../../models/liveSessionStatus';

/**
 * SessionDialog component
 */
interface IComponentProps {
  isOpen: boolean;
  onDismiss: () => void;
  subText?: string;
  sessions: LearnerSyllabusTask[];
  checkedSessions:LearnerSyllabusTask[];
  onCheckBoxChange: (sessions: LearnerSyllabusTask[]) => void;
  onSendInvite: (liveSessionStatus: ILiveSessionStatus) => void;
  sentSessionRegistrationIds:string[];
}

const useStyles = makeStyles({
  dialogContent: {
    width: '80%',
    height: '90%',
    maxWidth: 'none',
    marginLeft: '10%',
  },
  dialogTitle: {
    width: 'max-content',
    display: 'inline-block',
  },
  dialogBody: {
    height: '95%',
  },
  dialogCloseButton: {
    float: 'right',
    marginTop: '-3.5%',
    width: '100%',
  }
})

export const SessionDialog: React.FunctionComponent<IComponentProps> = props => {
  const { isOpen, onDismiss, subText, sessions,checkedSessions,onCheckBoxChange,onSendInvite,sentSessionRegistrationIds } = props;

  const [sessionsData, setSessionsData] = useState(sessions);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const isFetching = false;//useSelector<IApplicationState, boolean>(state => state.UserStore.isFetching, shallowEqual);

  const updateSessionsData = () => {   
    const updatedSessions =
    sessionsData?.map(item => ({
      ...item,
      inviteStatus: (item.inviteStatus==="NotSent" && 
      sentSessionRegistrationIds.includes(item.sessionRegistrationId))
      ?"Sent":item.inviteStatus,
      checked:checkedSessions.filter(x=>x.id===item.id && x.checked===true).length>0?true:undefined
    })) ?? [];
    setSessionsData(updatedSessions);
  };
  useEffect(() => { 
    if (checkedSessions) {
      updateSessionsData();    
    }
  }, [JSON.stringify(checkedSessions),sessions]);

  const onCancel = () => {  
    onDismiss();
  };
  const dialogContentProps = {
    closeButtonAriaLabel: 'Close',
    subText: subText
  };
  const labelId: string = 'dialogLabelCongrats';// useId('dialogLabelCongrats');
  const modalProps = React.useMemo(
    () => ({
      containerClassName: 'sessionDialogContent ms-Grid',
      titleAriaId: labelId
    }),
    [labelId]
  );

  const onCheckboxSelectAllChange = useCallback(
      (    event: { target: { checked: any; }; }) => {
      ai.appInsights?.trackEvent({name: "Session Dialog: All sessions checkbox selected: "+ sessionsData});
      const isAllChecked = event.target.checked;
      const newSessionDetails =
        sessionsData?.map(session => ({
          ...session,
          checked: !!isAllChecked
        })) ?? [];
      setSessionsData(newSessionDetails);
    },
    [sessionsData]
  );

  const onCheckboxChange = useCallback(
      (    event: { target: { id: any; checked: any; }; }) => {
      ai.appInsights?.trackEvent({name: "Session Dialog: Session checkbox selected: "+ sessionsData});
      const id = event.target.id;
      const isChecked = event.target.checked;
      const newSessionDetails =
        sessionsData?.map(session => ({
          ...session,
          checked: session.id === id ? isChecked : session.checked
        })) ?? [];
      setSessionsData(newSessionDetails);
        ClarityHelper.setCustomTag('SessionClick', `SessionId:${id}`);
    },
    [sessionsData]
  );

  const onSessionTitleChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    const sessionSearchDataQuery = sessionsData?.map(session => ({
      ...session,
      isHidden: !session.syllabusTask.name.toLowerCase().includes(newValue?.toLowerCase() ?? '')
    }));
    setSessionsData(sessionSearchDataQuery);

    const isAvailableDiv = document.getElementById('isAvailable');
    isAvailableDiv?.removeAttribute('role');
    isAvailableDiv?.removeAttribute('aria-label');
    if (sessionsData?.length !== sessionSearchDataQuery?.filter(session => session.isHidden === true).length) {
      isAvailableDiv?.setAttribute('role', 'alert');
      isAvailableDiv?.setAttribute('aria-label', 'Search results available');
    } else {
      isAvailableDiv?.setAttribute('role', 'alert');
      isAvailableDiv?.setAttribute('aria-label', 'No data available');
    }
    isAvailableDiv?.addEventListener('focusout', clearSearchSuggestions);
  };

  const clearSearchSuggestions = () => {
    setShowSuggestions(false);
  };

  const sessionDialogStyle = {
    marginTop: "10%",
    width: "70%",
    height: "70%",
    zIndex: '-1'
  }

  const tableBackground = {
    background: "#FFFFFF"
  }

  const classes = useStyles();

  return (
   <>
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          size="small"
          className={widgetStyle.footerBtn}>
            <Link>
          View all live sessions
          </Link>
        </Button>  
    </DialogTrigger>
    <DialogSurface className={classes.dialogContent}>
      <DialogTitle className={classes.dialogTitle} action={
              <DialogTrigger action="close">
                <Button className={classes.dialogCloseButton}
                  appearance="subtle"
                  aria-label="close"
                  onClick={onCancel}
                  icon={<Dismiss24Regular />}
                />
              </DialogTrigger>
            }>All live sessions</DialogTitle>
      <DialogBody className={classes.dialogBody}>
        <DialogContent>
          <LiveSessionsTable
            sessions={sessionsData}
            dashboardWidget={false}
            selectAllSessionCheckboxLabel='Select all sessions'
            checkBoxlLabelText='Select session title'
            onCheckBoxChange={onCheckBoxChange}
            onSendInvite={onSendInvite}
            sentSessionRegistrationIds={sentSessionRegistrationIds}
          />
        </DialogContent>
      </DialogBody>
    </DialogSurface>
  </Dialog>
  </>
  );
};

export default SessionDialog;