import * as React from 'react';

import { CustomIconProps, iconsMap } from '../../common/commonData';

/**
* CustomIcon component
*/

export const CustomIcon: React.FunctionComponent<CustomIconProps> = (props: CustomIconProps) => {
  const { iconName } = props;

  const Icon = iconsMap[iconName];

  return Icon == null ? null : (
    <Icon />
  );
};

export default CustomIcon;
