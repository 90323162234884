import React, { useEffect, useState } from "react";

import { LiveSession, IPatchEventSessionsRequest, Learner, LearnerSyllabusTask } from "../../models";

import { SendInvitesDialog } from "./SendInvitesDialog";

// import { Helper } from '../../utilities';

import {
  Button,
  Text,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";

// import { saveSessionInvites } from '../../services/deliveryService';

import { CalendarClock20Regular } from "@fluentui/react-icons";

import ".././styles/SelectedSessions.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { patchLiveSessions } from "../../services/deliveryService";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";

import ".././styles/SelectedSessions.scss";
import { saveLiveSessionStatus } from "../../services/blendedLearningService";
import { ILiveSessionStatus } from "../../models/liveSessionStatus";

/**
 * Selected sessions component
 */
interface IComponentProps {
  sessionsData?: LearnerSyllabusTask[];
  count: number;
  dashboardWidget: boolean;
  onSendInviteClick: (liveSessionStatus: ILiveSessionStatus) => void;
}

const useStyles = makeStyles({
  root: {
    width: "fit-content",
    height: "35px",
    display: "flex",
    alignItems: "center",
    marginRight: "auto",
  },
  selectedSessionStyle:{
    fontSize: "14px",
    fontWeight: "600"
  },
  icon: {
    color: "#4f52b2",
    marginLeft: "1em",
  },
});

export const SelectedSessions: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { sessionsData, count,onSendInviteClick } = props;

  const hasCheckedSessions = count !== 0;

  const payload: IPatchEventSessionsRequest[] = [];
  sessionsData?.forEach((session: LearnerSyllabusTask, index: number) => {
    if (session.checked) {
      payload.push({
        sessionRegId: session.sessionRegistrationId,
        sendInvite: session.checked,
      });
      ClarityHelper.setCustomTag('SendSessionInvite', `${session.syllabusTask.name}`);

    }
  });
  const liveSessionStatus={sessionRegistrationIds:[]} as ILiveSessionStatus;
  sessionsData?.forEach((session: LearnerSyllabusTask) => {
    if (session.checked) {
      liveSessionStatus.sessionRegistrationIds.push(session.sessionRegistrationId);     
    }
  });
 
  const queryClient = useQueryClient();

  const resendSessions = useMutation({
    mutationFn: (payload: any) => patchLiveSessions(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Sessions"] });
    },
  });

  const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
    queryKey: ["BlxpData"],
    queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
  }); 
  const learnerId=blxpData?.learner.learnerId??'';
  const deliveryId=blxpData?.delivery.id??'';
  useEffect(() => {
    if (!blxpDataLoading) {
    }
  }, [blxpData, blxpDataLoading]);
  const saveLiveSessions = useMutation({
    mutationFn: () => saveLiveSessionStatus(learnerId,deliveryId,liveSessionStatus),
    onSuccess: () => {  
      onSendInviteClick(liveSessionStatus);    
     
    },
  });
  const handleClickContinue = () => {
    resendSessions.mutate(payload);  
    saveLiveSessions.mutate();
    ClarityHelper.setCustomTag(
      "NumberOfSelectedSessionsToSendInvite",
      `${count}`
    );
    ai.appInsights?.trackEvent({
      name:
        "Selected Sessions: Number of selected sessions to send invite: " +
        { count },
    });
  };

  const sessionsSelectedLabel = "Number of sessions selected.";

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text className={classes.selectedSessionStyle} aria-label={sessionsSelectedLabel}>{count} Selected&nbsp;</Text>
      {hasCheckedSessions && (
        <>
          <CalendarClock20Regular className={classes.icon} />
          <SendInvitesDialog
            onContinue={handleClickContinue}
            count={count}
            emailAddress={blxpData?.learner?.email}
            locale="en-US"
            appearance="transparent"
          />
        </>
      )}
    </div>
  );
};

export default SelectedSessions;
