import React from 'react';
import { ITooltipHostStyles } from '@fluentui/react';
import { Button, Input, InputProps, Tooltip, makeStyles } from '@fluentui/react-components';
import { LearnerSyllabusTask } from '../../models';
import { Info16Regular, Search12Regular } from '@fluentui/react-icons';


import '../styles/SessionsSearch.scss';
import '../styles/SessionsTable.scss';
import IconTips from './IconTips';
import { ai } from '../../internal/ApplicationInsightsProvider/ApplicationInsightsService';
import { ClarityHelper } from '../../internal/ClarityHelper';

/**
 * SessionsSearch component
 */

export interface ISessionsSearchProps {
  sessions?: LearnerSyllabusTask[];
  showSuggestions?: boolean;
  onSearchChange: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void;
}

  const useStyles = makeStyles({
  searchStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    height: '35px'

  },
});

export const SessionsSearch: React.FunctionComponent<ISessionsSearchProps> = props => {
  const inlineBlockStyle: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' }
  };

 const styles = useStyles();

  const searchBoxInfoText = 'This search will filter to show sessions within this delivery that contain keywords you enter.';

  const { sessions, onSearchChange, showSuggestions } = props;
  const matchingSessions = sessions?.filter(session => session.isHidden === false).length;

  const onSessionTitleChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    onSearchChange(event, newValue);
  };

  const onChange: InputProps["onChange"] = (ev, data) => {
    ai.appInsights?.trackEvent({ name: 'SearchBoxChanged', properties: { value: data.value } });
    ClarityHelper.setCustomTag('SearchBox', `${data.value}`);
    onSearchChange(ev, data.value);
  };

  return (
      <div className={styles.searchStyles}>
        <span >
        <Tooltip content={searchBoxInfoText} relationship="label">
          <Button aria-label="Search For Sessions Information circle icon" appearance='transparent' icon={<Info16Regular />} />
        </Tooltip>
          </span>
        <Input contentBefore={<Search12Regular aria-label='Search For Sessions' className='iconSearchBox'  />} onChange={onChange} placeholder="Search" id="isAvailable" aria-live="polite"></Input >
      </div>
  );
};

export default SessionsSearch;
