import {
  Beaker24Regular,
  Book24Regular,
  ChartPerson24Regular,
  ChatBubblesQuestion24Regular,
  VideoClip24Regular,
  ShareScreenPersonOverlay24Regular,
} from "@fluentui/react-icons";

export type CustomIconProps = {
  iconName: keyof typeof iconsMap;
};

export const iconsMap = {
  Videos: VideoClip24Regular,
  Modules: Book24Regular,
  Survey: Book24Regular,
  Labs: Beaker24Regular,
  "Chalk Talks": ChartPerson24Regular,
  "Office Hours": ChatBubblesQuestion24Regular,
  "Lab Sessions": ShareScreenPersonOverlay24Regular,
};

export const taskTypes = Object.keys(iconsMap).filter(
  (item) => item !== "Lab Sessions" && item !== "Survey"
);
