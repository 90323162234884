import { Info24Regular } from "@fluentui/react-icons";
import { WidgetNoBorder } from "../lib/WidgetNoBorder";
import { Spinner, Text } from "@fluentui/react-components";

import { Delivery } from "../../models/delivery";
import SyllabusTable from "../components/SyllabusTable";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { ITooltipHostStyles } from "@fluentui/react";
import { SyllabusFocusArea } from "../components/SyllabusFocusArea";
import { SyllabusMenuFilter } from "../components/SyllabusMenuFilter";
import { liveSessionWidgetStyle } from "../lib/LiveSessionWidget.styles";
import { Learner } from "../../models";
import IconTips from "../components/IconTips";
import Helper from "../../common/helper";

import "../styles/BlendedLearning.scss";
import "../styles/Syllabus.scss";

/**
 * Syllabus component
 */

interface ISyllabus {
  currentDelivery: Delivery;
  currentLearner: Learner;
  eventStartDate?: string;
  eventEndDate?: string;
  selectedFilters: string[];
  selectedFocusArea: string;
  currentWeekNumber: number;
  channelId?: string;
  adjustFilterPosition:boolean
}

// let delivery: Delivery;
export class SyllabusWidget extends WidgetNoBorder<ISyllabus> {
  protected async getData(): Promise<ISyllabus> {
    let blxpMainData =
      await BlendedLearningContext.getInstance().getBlendedLearning();

    let weekNumber = Helper.getCurrentWeekForBL(
      new Date(blxpMainData.delivery?.startDate),
      new Date(blxpMainData.delivery?.endDate),
      new Date()
    );

    return {
      currentDelivery: blxpMainData.delivery,
      currentLearner: blxpMainData.learner,
      eventStartDate: new Date(
        blxpMainData.delivery.startDate
      ).toLocaleDateString("en-US"),
      eventEndDate: new Date(blxpMainData.delivery.endDate).toLocaleDateString(
        "en-US"
      ),
      selectedFilters: [],
      selectedFocusArea: "",
      currentWeekNumber: weekNumber,
      channelId: blxpMainData.channelId,
      adjustFilterPosition:false
    };
  }

  inlineBlockStyle: Partial<ITooltipHostStyles> = {
    root: {
      display: "inline-block",
    },
  };

  infoText =
    "This syllabus section provides you a detailed look at the tasks within your course. Use this to navigate to your course material, mark tasks as complete or flag them for future reference.";

  protected headerContent(): JSX.Element | undefined {
    const onFocusAreaChange = (selectedFocusArea: string) => {
      this.setState({ selectedFocusArea: selectedFocusArea });
    };
    const onSyllabusFilterChange = (selectedFilters: string[]) => {
      this.setState({ selectedFilters: selectedFilters });      
    };

    return (
      <>
        <div className="ms-fontWeight-bold syllabusHeader">
          <h1 className="headerSmall" aria-label="Syllabus">
            <Text
              className={liveSessionWidgetStyle.headerText}
            >
              Syllabus
            </Text>
            <span >
              <IconTips
                iconTitle="Syllabus"
                text={this.infoText}
                icon="info"
                ariaTextId="syllabusText"
              />
            </span>
          </h1>
          {this.state.currentWeekNumber != null &&
            this.state.currentWeekNumber != undefined && (
              <SyllabusFocusArea
                onFocusAreaChange={onFocusAreaChange}
                currentWeekNumber={this.state.currentWeekNumber}
                channelId={this.state.channelId}
                isShowSelectedFousArea={this.props.isShowSelectedFousArea}
                learnerId={this.state.currentLearner.learnerId}
                deliveryId={this.state.currentDelivery.id}
              />
            )}        
          <SyllabusMenuFilter onSyllabusFilterChange={onSyllabusFilterChange} adjustPosition={this.state.adjustFilterPosition} />
        </div>
      </>
    );
  }

  protected bodyContent(): JSX.Element | undefined {
    let CurrentDate = new Date();
    let StartDate = new Date(this.state.currentDelivery.startDate);
    const onDataChange = () => {
      this.setState({ adjustFilterPosition: !this.state.adjustFilterPosition });
    };
    return (
      <>
      { (StartDate > CurrentDate) ? (  
        <div className="grayBackground">
          <Info24Regular
            aria-label="Info circle icon"
            className="iconRightSmall"
          />
            <Text> 
            Please note that the syllabus may change before the course starts (on{" "}
            {this.state.eventStartDate})
          </Text></div>) : ( <></> )
      } 
        <div className="syllabusTableWrapper">
          <SyllabusTable
            isShowSelectedFousArea={this.props.isShowSelectedFousArea}
            selectedFilters={this.state.selectedFilters}
            selectedFocusArea={this.state.selectedFocusArea}
            learner={this.state.currentLearner}
            deliveryId={this.state.currentDelivery.id}
            onDataChange={onDataChange}
          />
        </div>
      </>
    );
  }

  protected loadingContent(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label="Loading..." labelPosition="below" />
      </div>
    );
  }
}
