import React from "react";
import {
  Spinner,
  Text,
} from "@fluentui/react-components";

import { Delivery, Learner } from "../../models";
import { yourProgressWidgetStyle } from "../lib/YourProgressWidget.styles";
import YourProgress from "../components/YourProgress";

import "../styles/Common.css";
import "../styles/Task.css";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { Widget } from "../lib/Widget";

interface ISyllabus {
  currentDelivery: Delivery,
  currentLearner: Learner,
  channelName: string,  
  channelId: string
}

export class FocusAreaYourProgressWidget extends Widget<ISyllabus> {

    protected async getData(): Promise<ISyllabus> {  
        const blxpMainData = await BlendedLearningContext.getInstance().getBlendedLearning();
         return {
          currentDelivery: blxpMainData.delivery,
          currentLearner: blxpMainData.learner,
          channelName: blxpMainData.channelName,
          channelId:blxpMainData.channelId
        } as ISyllabus;
      }


      protected loadingContent(): JSX.Element | undefined {
        return (
          <div className="loading-layout">
            <Spinner label="Loading..." labelPosition="below" />
          </div>
        );
      }
    
      protected headerContent(): JSX.Element | undefined {
        return (
          <h1 className={yourProgressWidgetStyle.headerText} aria-label="Focus Area Progress" tabIndex={0}>Focus Area Progress</h1>
        );
      }

      protected bodyContent(): JSX.Element | undefined {
        return(
          <YourProgress learner={this.state.currentLearner} deliveryId={this.state.currentDelivery.id} 
          isShowFocusArea={true} 
          channelId={this.state.channelId} 
          isShowHeader={false} 
          isShowRightColumn={true}/>
        );
      }



}