import { makeStyles } from "@fluentui/react-components";
import * as React from "react";

import { LearnerSyllabusTask } from "../../models";

import "../styles/Syllabus.scss";

/**
 * YourProgressTasks component
 */
interface IComponentProps {
  tasks: LearnerSyllabusTask[];
  taskType: string;
}

const useStyles = makeStyles({
  tasksContainer: {
    float: "left",
    marginRight: "0.25em",
  },
});

export const YourProgressTasksCount: React.FunctionComponent<
  IComponentProps
> = (props) => {
  const { tasks, taskType } = props;

  const classes = useStyles();

  const consolidatedTasks = tasks.map((item) => ({
    ...item,
    isOfficeHours:
      item.syllabusTask.syllabusTaskType.name === "Office Hours" ||
      item.syllabusTask.syllabusTaskType.name === "Lab Sessions",
  }));

  return (
    <>
      <span className={classes.tasksContainer}>
        {taskType === "Office Hours" && (
          <>
            {
              consolidatedTasks.filter(
                (task) =>
                  (task.syllabusTask.syllabusTaskType.name === "Office Hours" ||
                    task.syllabusTask.syllabusTaskType.name ===
                      "Lab Sessions") &&
                  task.isTaskCompleted
              ).length
            }
            /
            {
              consolidatedTasks.filter(
                (task) =>
                  task.syllabusTask.syllabusTaskType.name === "Office Hours" ||
                  task.syllabusTask.syllabusTaskType.name === "Lab Sessions"
              ).length
            }
          </>
        )}
        {taskType !== "Office Hours" && (
          <>
            {
              consolidatedTasks.filter(
                (task) =>
                  task.syllabusTask.syllabusTaskType.name === taskType &&
                  task.isTaskCompleted
              ).length
            }
            /
            {
              consolidatedTasks.filter(
                (task) => task.syllabusTask.syllabusTaskType.name === taskType
              ).length
            }{" "}
          </>
        )}
      </span>
    </>
  );
};

export default YourProgressTasksCount;
