import { Button, Spinner, Text } from "@fluentui/react-components";
import { WidgetNoBorder } from "../lib/WidgetNoBorder";

import "../styles/Common.css";
import "../styles/HomeOverview.scss";
import { LiveSessionCards } from "../components/LiveSessionCards";
import { focusAreaLiveSessionWidgetStyle } from "../lib/FocusAreaLiveSessionsWidget.styles";
import {  getTopicsByDeliveryId } from "../../services/deliveryService";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { app } from "@microsoft/teams-js";

interface ILiveSession {
  channelId?: string;
  learnerId:string,
  deliveryId:string,
  videoTabDeepLink: string;
}

export class FocusAreaLiveSessionsWidget extends WidgetNoBorder<ILiveSession> {
  protected async getData(): Promise<ILiveSession> {
    let blxpMainData =
      await BlendedLearningContext.getInstance().getBlendedLearning();  
   
    let deliveryTopicData = await getTopicsByDeliveryId(blxpMainData.delivery.id);

    return {
      channelId:blxpMainData.channelId,
      learnerId:blxpMainData.learner.id,
      deliveryId:blxpMainData.delivery.id,
      videoTabDeepLink: deliveryTopicData?.find(dt => dt.topics?.name == "Orientation")?.videosTabId as string
    };
  }

  protected headerContent(): JSX.Element | undefined {
    return (
      <div>
        <h1 aria-label="Live sessions" className={focusAreaLiveSessionWidgetStyle.header} tabIndex={0}>
            Live sessions
        </h1>
        <div className={focusAreaLiveSessionWidgetStyle.subheader}>
          <Text className={focusAreaLiveSessionWidgetStyle.headerText}>
            We offer live sessions where you can interact with trainers and
            other learners, ask questions, and gain a deeper knowledge of the
            course topics. You can review the Video tab for the recorded chalk
            talks sessions. Please note that the recording of office hours and
            lab sessions is subject to variability.
          </Text>
          <Button className={focusAreaLiveSessionWidgetStyle.headerButton} onClick={() => this.NavigateToVideosTab()}>            
              Go to recordings
          </Button>
        </div>
      </div>
    );
  }

  protected bodyContent(): JSX.Element | undefined {
    return <LiveSessionCards learnerId={this.state.learnerId} deliveryId={this.state.deliveryId} channelId={this.state.channelId} />
    
  }
  protected loadingContent(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label="Loading..." labelPosition="below" />
      </div>
    );
  }
  
  protected async NavigateToVideosTab() : Promise<void> {
   await app.openLink(this.state.videoTabDeepLink);
  }
}
