import * as React from 'react';
import { Text, Image } from '@fluentui/react-components';

import '../styles/CustomErrorMessage.scss';

// interface CustomErrorMessageProps {
//     widget: boolean;
// }

class CustomErrorMessage extends React.Component {
    render() {
        return (
        <div className='cardStyle'>
        <Text className='textStyle'>
            The Blended Learning Teams App is currently down for maintenance.
            We expect to be back shortly.
            Thanks for your patience.
        </Text>
        <Image className='imageStyle' src={require('../../assets/images/dog.png')} alt="Sorry, we are down for maintenance. We will be back up and running shortly."/>
        </div>
        );
      }
};

export default CustomErrorMessage;
