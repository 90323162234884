import { ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import { Info16Regular, QuestionCircle16Regular } from '@fluentui/react-icons';

import { Button, Tooltip, type TooltipProps } from "@fluentui/react-components";
import * as React from 'react';

import '../styles/BlendedLearning.scss';
import { TeamsFxContext } from '../../internal/context';

import '../styles/IconTips.scss';

/**
 * IconTips component
 */

interface IComponentProps {
    icon: string;
    text: string | JSX.Element;
    ariaTextId: string;
    iconTitle:string;
}

export const IconTips: React.FunctionComponent<IComponentProps> = props => {

    const { icon, text, ariaTextId,iconTitle } = props;

    let iconClass = 'headerIcon'; 

    <TeamsFxContext.Consumer>
    {
        ({themeString}) => (
            themeString === 'default' ? iconClass = 'headerIcon' : iconClass = 'headerIconDark'
        )
    }
    </TeamsFxContext.Consumer>
    

  return (
    <Tooltip content={text} relationship="description" ><Button icon={ 
      icon === 'question' ? <QuestionCircle16Regular tabIndex={0}  aria-label={`${iconTitle} Question circle icon`} aria-hidden={false} aria-describedby={ariaTextId} /> : 
      <Info16Regular tabIndex={0}  aria-label={`${iconTitle} Information circle icon`} aria-hidden={false} aria-describedby={ariaTextId} /> } size="small" appearance="transparent"/></Tooltip>
  );
};

export default IconTips;