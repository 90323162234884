import * as axios from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { applicationConfig } from "../common/settings";
import { EndpointFunction,  EndpointFunctionwithExtraParam,  EndpointFunctionwithThreeParam,  GetDefaultRequestConfig } from "../common/ServiceConfiguration";
import Logger from "../common/logger";
import { authentication } from "@microsoft/teams-js";
import { Learner } from "../models";

export async function getLearnerProfile(preferredEmailAddress: string) : Promise<Learner> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.getLearnerProfile as EndpointFunction

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.get<Learner>(`${endpoint(preferredEmailAddress)}`, requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                //Retrieve learner profile using workemail
                return await getLearnerProfileUsingWorkEmail(preferredEmailAddress);

            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }
            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as Learner;
        }
        Logger.LogError('', err);
        return {} as Learner;
    }

}

export async function getLearnerProfileUsingWorkEmail(workEmail: string) : Promise<Learner> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.getLearnerProfileUsingWorkEmail as EndpointFunction

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.get<Learner>(`${endpoint(workEmail)}`, requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `There may be a problem with the api, `;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }
            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as Learner;
        }
        Logger.LogError('', err);
        return {} as Learner;
    }

}

export async function makeApiCall(endpointFunction: EndpointFunctionwithThreeParam,learnerId:string,deliveryId: string,tpid: string) {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;       
        const endpoint = endpointFunction

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.post(`${endpoint(learnerId,deliveryId,tpid)}`,null, requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {};
        }
        Logger.LogError('', err);
        return {} ;
    }
}
export async function postTrainerData(learnerId:string,deliveryId: string,tpid: string) {
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.postTrainerData as EndpointFunctionwithThreeParam
    return makeApiCall(endpoint, learnerId, deliveryId, tpid);
}

export async function validateRegistration(learnerId:string,deliveryId: string,tpid: string) {
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.validateRegistration as EndpointFunctionwithThreeParam
    return makeApiCall(endpoint, learnerId, deliveryId, tpid);
}