import { IServiceConfiguration } from "./ServiceConfiguration";

export interface IApplicationConfig {
    services: {
      [key: string]: IServiceConfiguration;
    };
    appInsightsConnectionString: string;
    defaultAuthScopes: string[];
    clarityProjectId: string;
  }


  export const applicationConfig: IApplicationConfig = {
    services: {
      apiServices: {
        baseUri: process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_URL as string,
        apiKey: process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_Apim_Subscription_Key as string,
        endpoints: {
          readFeatureFlag: '/Enrollment/v2/api/Profile/ReadFeatureFlag',
          HomeOverView: '/blxp/v1/homeOverviews',
          Sessions: (deliveryId: string, learnerId: string,tpId:string) => `/Enrollment/v2/api/EventManagement/eventSessions?deliveryId=${deliveryId}&learnerId=${learnerId}&tpId=${tpId}`,
          patchSessions: `/Enrollment/v2/api/EventManagement/eventSessions`,
          teamsSessions: (teamsGroupId: string) => `/blxp/v1/teamsSessions/${teamsGroupId}/delivery`,
          Delivery: (deliveryId: string) => `/blxp/v1/deliveries/${deliveryId}`,
          CourseTopic: (courseId: string) => `/blxp/v1/courses/${courseId}/topics`,
          PutLearnerDeliveryWelcomeTourStatus: (deliveryId: string, learnerId: string) =>
          `/blxp/v1/homeOverviews/LearnerDeliveryWelcomeTourStatus/${deliveryId}/${learnerId}`,
          GetLearnerDeliveryWelcomeTourStatus: (deliveryId: string, learnerId: string) =>
          `/blxp/v1/homeOverviews/LearnerDeliveryWelcomeTourStatus/${deliveryId}/${learnerId}`,      
          LearnerSyllabusTasks: (learnerId: string, deliveryId: string) => `/blxp/v1/syllabuses/learnersyllabustasks/${learnerId}/${deliveryId}`,
          UpdateLearnerSyllabusTaskCompletedState: (id: string,userName:string) => `/blxp/v1/syllabuses/updatelearnersyllabustaskcompletedstate/${id}/${userName}`,
          UpdateLearnerSyllabusTaskFlaggedState: (id: string,userName:string) => `/blxp/v1/syllabuses/updatelearnersyllabustaskflaggedstate/${id}/${userName}`,
          DeliveryTopic: (deliveryId: string, teamChannelId?: string) => teamChannelId ? `/blxp/v1/deliveries/${deliveryId}/topics?teamChannelId=${teamChannelId}` : `/blxp/v1/deliveries/${deliveryId}/topics`,
          getLearnerSyllabusTaskLabUrl: (learnerSyllabusTaskId: string, locale:string) => `/blxp/v1/labs/learnerLabUrl?learnerSyllabusTaskId=${learnerSyllabusTaskId}&locale=${locale}`,
          getTrainerClassViewUrl: (deliveryId: string) => `/blxp/v1/labs/TrainerLabUrl/${deliveryId}`,
          getLearnerTeamState: (learnerId: string, deliveryId: string) => `/blxp/v1/teamsSessions/learnerteamstate/${learnerId}/${deliveryId}`,
          saveLearnerTeamState: (learnerId: string, deliveryId: string) => `/blxp/v1/teamsSessions/learnerteamstate/${learnerId}/${deliveryId}`,        
          getLearnerProfile: (preferredEmailAddress: string) => `profile/v1/Profile/GetLearnerProfile?preferredEmailAddress=${preferredEmailAddress}`,
          getLearnerProfileUsingWorkEmail: (workEmail: string) => `profile/v1/Profile/GetLearnerProfile?workEmail=${workEmail}`,
          getMediaAccess: (mediaName: string, mediaResourceType: string) => `/blxp/v1/media/authorize?mediaName=${mediaName}&mediaResourceType=${mediaResourceType}`,
          getLiveSession: (learnerId: string, deliveryId: string) => `/blxp/v1/syllabuses/getliveSession/${learnerId}/${deliveryId}`,
          saveLiveSession: (learnerId: string, deliveryId: string) => `/blxp/v1/syllabuses/saveliveSession/${learnerId}/${deliveryId}`,
          postTrainerData: (learnerId: string, deliveryId: string,tpId:string) => `/blxp/v1/deliveries/trainer?learnerId=${learnerId}&deliveryId=${deliveryId}&tpId=${tpId}`,
          validateRegistration: (learnerId: string, deliveryId: string,tpId:string) => `/blxp/v1/learners/validateRegistration?learnerId=${learnerId}&deliveryId=${deliveryId}&tpId=${tpId}`
        }
      }
    },
    appInsightsConnectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING as string,
    clarityProjectId: process.env.REACT_APP_CLARITY_PROJECT_ID as string,
    defaultAuthScopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation']
  };