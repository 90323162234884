import { TeamsUserCredential } from "@microsoft/teamsfx";
import { getDeliveryTeamsId } from "../services/deliveryService";
import { app } from "@microsoft/teams-js";
import { BlendedLearning, Delivery, Learner } from "../models";
import Logger from "../common/logger";
import { getLearnerProfile } from "../services/learnerService";

export class TeamsUserCredentialContext {
  private static instance: TeamsUserCredentialContext;
  private credential: TeamsUserCredential | undefined;
  private constructor() { }

  public static getInstance(): TeamsUserCredentialContext {
    if (!TeamsUserCredentialContext.instance) {
      TeamsUserCredentialContext.instance = new TeamsUserCredentialContext();
    }
    return TeamsUserCredentialContext.instance;
  }

  public setCredential(credential: TeamsUserCredential) {
    this.credential = credential;
  }

  public getCredential() {
    if (!this.credential) {
      this.credential = new TeamsUserCredential({
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
        clientId: process.env.REACT_APP_CLIENT_ID!,
      });
    }
    return this.credential;
  }
}

export class TeamsContext {
  private static instance: TeamsContext;
  private teamInformation: app.Context | undefined;
  private constructor() { }

  public static getInstance(): TeamsContext {
    if (!TeamsContext.instance) {
      TeamsContext.instance = new TeamsContext();
    }

    return TeamsContext.instance;
  }




  public async getTeamsInformation() {
    // const token = await authentication.getAuthToken();
    // console.log(`my token: ${token}`)
    if (!this.teamInformation) {
      try {
        //let team = (await app.getContext()).team;
        this.teamInformation = await app.getContext();
      }
      catch (e) {
        Logger.LogCritical("Unable to get Teams Context", e);
      }

    }
    return this.teamInformation;
  }

}

export class DeliveryContext {
  private static instance: DeliveryContext;
  private delivery: Delivery | undefined;

  private constructor() { }

  public static getInstance(): DeliveryContext {
    if (!DeliveryContext.instance) {
      DeliveryContext.instance = new DeliveryContext();
    }
    return DeliveryContext.instance;
  }

  public setDelivery(delivery: Delivery) {
    this.delivery = delivery;
  }

  public async getDelivery() {
    if (!this.delivery) {
       let teamInformation = await TeamsContext.getInstance().getTeamsInformation();
       let teamsId = teamInformation?.team?.groupId;
       // private channel shenanigans
       let ownerGroupId = teamInformation?.channel?.ownerGroupId;

       this.delivery = await getDeliveryTeamsId(teamsId || ownerGroupId || "");
    }
    return this.delivery;
  }
}

export class BlendedLearningContext {
  private static instance: BlendedLearningContext;
  private blendedLearning: BlendedLearning | undefined;

  private constructor() { }

  public static getInstance(): BlendedLearningContext {
    if (!BlendedLearningContext.instance) {
      BlendedLearningContext.instance = new BlendedLearningContext();
    }
    return BlendedLearningContext.instance;
  }

  public setDelivery(blendedLearning: BlendedLearning) {
    this.blendedLearning = blendedLearning;
  }

  public async getBlendedLearning() {
    if (!this.blendedLearning) {

      let teamInformation = await TeamsContext.getInstance().getTeamsInformation();
      const groupId = teamInformation?.team?.groupId; //GroupId and TeamsId are the same
      const delivery = await DeliveryContext.getInstance().getDelivery();

      const email = this.getEmail(teamInformation?.user?.userPrincipalName ?? "");

      const learnerInfo=await getLearnerProfile(email);
      const learner: Learner = {
        id: learnerInfo.id,
        learnerId: learnerInfo.id,
        email: email,
        userObjectId: teamInformation?.user?.id ?? "",
        userPrincipalName: teamInformation?.user?.userPrincipalName ?? "",
        tpid:learnerInfo.tpid ?? ""

      };
      
      const blxpContext: BlendedLearning = {
        //delivery: undefined,
        teamsId: groupId || '',
        learner: learner,
        delivery: delivery,
        //user: teamInformation?.user,
        channelName: teamInformation?.channel?.displayName,
        teamName: teamInformation?.team?.displayName,
        channelId: teamInformation?.channel?.id
      };
      this.blendedLearning = blxpContext; 
      sessionStorage.setItem("deliveryId", delivery.deliveryId);
      sessionStorage.setItem("learnerId", learner.learnerId);
      sessionStorage.setItem("courseName", delivery.course.name);
      sessionStorage.setItem("teamName", blxpContext.teamName||'');
      sessionStorage.setItem("teamsId", blxpContext.teamsId||'');
      app.notifySuccess();
    }
    return this.blendedLearning;
  }

  private getEmail(userPrincipalName: string): string {
    let email = userPrincipalName.split("#")[0];
    if (email.indexOf("@") === -1) {
      const lastUnderscoreIndex = email.lastIndexOf("_");
      if (lastUnderscoreIndex !== -1) {
        return email.substring(0, lastUnderscoreIndex) + "@" + email.substring(lastUnderscoreIndex + 1);
      }
    }
    return email;
  }
}
