import { makeStyles } from "@fluentui/react-components";
import * as React from "react";

import { LearnerSyllabusTask } from "../../models";
import Task from "./Task";

import "../styles/Syllabus.scss";
import { getMediaSASToken } from "../../services/mediaServices";
import { useQuery } from "@tanstack/react-query";
import mediaAccessContext from "../../internal/mediaAccessContext";

/**
 * YourProgressTasks component
 */
interface IComponentProps {
  tasks: LearnerSyllabusTask[];
  taskType: string;
  weekNumber: number;
  learnerEmail: string;
}

const useStyles = makeStyles({
  tasksContainer: {
    float: "left",
    marginRight: "0.15em",
  },
});

export const YourProgressTasks: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { tasks, taskType, weekNumber, learnerEmail } = props;

  const classes = useStyles();

  const consolidatedTasks = tasks.map((item) => ({
    ...item,
    isOfficeHours:
      item.syllabusTask.syllabusTaskType.name === "Office Hours" ||
      item.syllabusTask.syllabusTaskType.name === "Lab Sessions",
  }));

  const { data: mediaToken, isLoading: isMediaSASTokenLoading } = useQuery({
    queryKey: ["MediaSASToken"],
    queryFn: () => getMediaSASToken("videos", "1"),
    staleTime: 86400000, // 23 hours
  });

  const MediaContext = React.createContext(mediaToken);
  return (
    <>
    <mediaAccessContext.Provider value={mediaToken}>
      {consolidatedTasks.map((task, index) => (
        <span className={classes.tasksContainer} key={index}>
          {task.deliveryTopic.weekNumber === weekNumber &&
            taskType === "Office Hours" && (
              <>
                {(task.syllabusTask.syllabusTaskType.name === "Office Hours" ||
                  task.syllabusTask.syllabusTaskType.name ===
                    "Lab Sessions") && (
                  <Task task={task} learnerEmail={learnerEmail} />
                )}
              </>
            )}

          {task.deliveryTopic.weekNumber === weekNumber &&
            task.syllabusTask.syllabusTaskType.name === taskType &&
            taskType !== "Office Hours" && (
              <Task task={task} learnerEmail={learnerEmail} />
            )}
        </span>
      ))}
      </mediaAccessContext.Provider>
    </>
  );
};

export default YourProgressTasks;
