import { makeStyles, shorthands, Text } from "@fluentui/react-components";
import * as React from "react";
import { OverviewVideoCard } from "./OverviewVideoCard";
import { useQuery } from "@tanstack/react-query";
import { getLearnerSyllabusTasks } from "../../services/blendedLearningService";
import { getMediaSASToken } from "../../services/mediaServices";
import { useState } from "react";
import { Learner, LearnerSyllabusTask, mediaAccessResult } from "../../models";
import { SyllabusTaskTypes } from "../../common/syllabusTaskTypes";
import { BlendedLearningContext } from "../../internal/singletonContext";
import mediaAccessContext from "../../internal/mediaAccessContext";

const useStyles = makeStyles({
  cardsWrapper: {
    display: "flex",
    ...shorthands.gap("1em"),
    alignContent: "space-between",
    flexWrap: "wrap",
  },
  bodyNoMorevideos: {
    height: "10em",
    marginTop: "7.5em",
  },
  bodyNoMorevideosText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    marginLeft: "22.5em",
  },
});

/**
 * OverviewVideoCards component
 */
interface IComponentProps {
  learner: Learner;
  deliveryId: string
}

export const OverviewVideoCards: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { learner, deliveryId } = props;
  const [items, setItems] = useState<LearnerSyllabusTask[]>([]);
  const [mediaSASToken, setMediaSASToken] = useState<mediaAccessResult>();

  const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
    queryKey: ["BlxpData"],
    queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
  });
  const { data: tasksData, isLoading: isTasksDataLoading } = useQuery({
    queryKey: ["UserTasksData"],
    queryFn: () => getLearnerSyllabusTasks(learner.id, deliveryId),
  });

  const { data: mediaToken, isLoading: isMediaSASTokenLoading } = useQuery({
    queryKey: ["MediaSASToken"],
    queryFn: () => getMediaSASToken("videos", "1"),
    staleTime: 86400000, // 23 hours
  });

  const MediaContext = React.createContext(mediaToken);


  React.useEffect(() => {
    if (!isTasksDataLoading) {
      const videos = tasksData != null ? tasksData.filter((x: LearnerSyllabusTask) => x.deliveryTopic.teamChannelId == blxpData?.channelId && x.syllabusTask.syllabusTaskType.name == SyllabusTaskTypes.Videos) : [];
      setItems(videos);
    }
  }, [tasksData, isTasksDataLoading]);
  const classes = useStyles();

  return (
    <>
      <mediaAccessContext.Provider value={mediaToken}>
        {items != null && items.length > 0 ?
          (
            <span className={classes.cardsWrapper}>
              {items.map((item: LearnerSyllabusTask, index) => (
                <OverviewVideoCard task={item} key={`OverviewVideoCard_${index}`} email={learner.email} />
              ))}
            </span>) :
          (<div className={classes.bodyNoMorevideos}> <Text className={classes.bodyNoMorevideosText}>No more videos for this course.</Text></div>)
        }
      </mediaAccessContext.Provider>
    </>
  );
};

export default OverviewVideoCards;
function useefect(arg0: () => void, arg1: (boolean | mediaAccessResult | undefined)[]) {
  throw new Error("Function not implemented.");
}

