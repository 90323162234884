import { createContext } from "react";

import { TeamsUserCredential } from "@microsoft/teamsfx";
import { Delivery } from "../models/delivery";

export const TeamsFxContext = createContext<{
  themeString: string,
  credential?: TeamsUserCredential,
  deliveryDetails?: Delivery
}>({
  themeString: "",
  credential: undefined,
  deliveryDetails: undefined
});
