export class ClarityHelper {
  public static setCustomTag = (key: string, value: string) => {
    if (typeof window !== undefined) {
      const wnd = window as any;    
    /*  wnd['clarity']('set', 'DeliveryId', sessionStorage.getItem("deliveryId"));
     wnd['clarity']('set', 'LearnerId', sessionStorage.getItem("learnerId"));
     wnd['clarity']('set', 'CatalogName', sessionStorage.getItem("courseName"));
     wnd['clarity']('set', 'teamName', sessionStorage.getItem("teamName"));
     wnd['clarity']('set', 'teamsId', sessionStorage.getItem("teamsId"));
     wnd['clarity']('set', key, value); */
    }
  };
}
