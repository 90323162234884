import { mergeStyleSets } from "@fluentui/react";
import { tokens } from "@fluentui/tokens";

export const focusAreaLiveSessionWidgetStyle = mergeStyleSets({
  root: {
    background: "none",
    border: "none",
  },
  header: {
    wrap: "wrap",
    height: "max-content",
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    fontSize: "14px !important",
  },
  subheader: {
    display: "flex",
    gap: "1em",
    alignContent: "space-between",
    marginBottom: "2em",
  },
  headerText: {
    display: "flex",
  },
  headerButton: {
    width: "22em",
    height: "2.5em",
  },
  bodyNoMoreSessions: {
    height: "10em",
    marginTop: "7.5em",
  },
  bold: {
    fontWeight: tokens.fontWeightSemibold,
  },
});
