import { LearnerSyllabusTask } from "../models";



export default class Helper{


  public static filterLiveSessions(liveSessionsData: LearnerSyllabusTask[]) {

    return liveSessionsData.filter((session) => (session.syllabusTask.platform.name === "Microsoft Teams" &&
      session.syllabusTask.syllabusTaskType.name ===
          "Chalk Talks") ||
      (session.syllabusTask.platform.name === "Microsoft Teams" &&
      session.syllabusTask.syllabusTaskType.name ===
          "Office Hours") ||
      (session.syllabusTask.platform.name === "Microsoft Teams" &&
      session.syllabusTask.syllabusTaskType.name ===
          "Lab Sessions"));
  }

    public static getCurrentWeekForBL(eventStartDate: Date, eventEndDate: Date, currentDateTimeNow: Date) {
        let currentWeekNumber = 0;
    
        if (currentDateTimeNow < eventStartDate) {
          currentWeekNumber = 0;
        } else if (currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 6)) {
          currentWeekNumber = 1;
        } else if (
          currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 6) &&
          currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 13)
        ) {
          currentWeekNumber = 2;
        } else if (
          currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 13) &&
          currentDateTimeNow <= Helper.addDaysToDate(eventStartDate, 20)
        ) {
          currentWeekNumber = 3;
        } else if (currentDateTimeNow > Helper.addDaysToDate(eventStartDate, 20) && currentDateTimeNow < eventEndDate) {
          currentWeekNumber = 4;
        } else if (currentDateTimeNow >= eventEndDate) {
          currentWeekNumber = 5;
        }
        return currentWeekNumber;
      }

      public static addDaysToDate(date: Date, days: number) {
        const updatedDate = new Date(date);
        updatedDate.setDate(date.getDate() + days);
        return updatedDate;
      }
      //Hard-coded for now
      public static getLocale(){
        const locale = "en-US";
        return locale; 
      }

}