import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

import * as H from 'history';

/**
 * A service that initializes a single instance of the Application Insights API and exposes it for use
 */
class ApplicationInsightsService {
  /** Reference to the Application Insights React Plugin instance */
  public reactPlugin = new ReactPlugin();

  /** Reference to an instance of the Application Insights API */
  public appInsights?: ApplicationInsights;

  /**
   * Initialize the Application Insights class
   *
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   * @param {History} browserHistory - client's browser history, supplied by the withRouter HOC
   */
  public initialize = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error('Could not initialize Telemetry Service. No instrumentation key has been specified.');
    }

    const browserHistory = H.createBrowserHistory();
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service. No browser history has been provided.');
    }

    const clickPluginInstance = new ClickAnalyticsPlugin();
    // Click Analytics configuration
    const clickPluginConfig = {
      autoCapture: true,
      useDefaultContentNameOrId: true
    };

    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: instrumentationKey,
        enableAutoRouteTracking: true,
        isCookieUseDisabled: true,
        maxBatchInterval: 5000,
        enableCorsCorrelation: true,
        disableFetchTracking: false,
        extensions: [this.reactPlugin, clickPluginInstance],
        extensionConfig: {
          [this.reactPlugin.identifier]: {
            history: browserHistory
          },
          [clickPluginInstance.identifier]: clickPluginConfig
        }
      }
    });

    this.appInsights.loadAppInsights();
    // this.appInsights.trackPageView();
  };
}

const ai = new ApplicationInsightsService();

export { ai };
