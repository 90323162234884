import { Link, Spinner, makeStyles } from "@fluentui/react-components";
import { WindowNew16Regular } from "@fluentui/react-icons";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { getLearnerSyllabusTaskLabUrl } from "../../services/blendedLearningService";

interface Props {
  learnerSyllabusTaskId: string;
  locale: string;
  linkText: string;
}

const LabLink: React.FC<Props> = ({ learnerSyllabusTaskId, locale, linkText }) => {

    const [isLabLoading, setIsLabLoading] = useState(false);

    const fetchUrl = useMutation({
        mutationFn: () => {return getLearnerSyllabusTaskLabUrl(learnerSyllabusTaskId, locale)},
      });

    const handleClick = async () => {
        setIsLabLoading(true);
        const response = await fetchUrl.mutateAsync();
        window.open(response, "_blank");
        setIsLabLoading(false);
    };

    const handleKeyDown = async (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setIsLabLoading(true);
            const response = await fetchUrl.mutateAsync();
            window.open(response, "_blank");
            setIsLabLoading(false);
        }
      };

    return (
        <>
            <Link aria-label={linkText} onClick={handleClick} as="a" disabled={isLabLoading} onKeyDown={handleKeyDown}>
                {linkText} <WindowNew16Regular />
            </Link>
        </>
        
    );
};

export default LabLink;