import { mergeStyleSets } from "@fluentui/react";

export const yourProgressWidgetStyle = mergeStyleSets({
  root: {
    backgroundColor: "none",
    borderStyle: "none",
    boxShadow: "none",
  },
  header: {
    display: "grid",
    alignItems: "center",
    height: "max-content"
  },
  headerText: {
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    fontSize: "14px !important",
  },
  headerIcon: {
    display: "grid",
    position: "relative",
    left: "0.15em",
    top: "0.1em",
  },
});
