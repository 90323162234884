import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Text,
} from "@fluentui/react-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { patchLiveSessions } from "../../services/deliveryService";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { IPatchEventSessionsRequest, LearnerSyllabusTask } from "../../models";
import { ClarityHelper } from "../../internal/ClarityHelper";

import ".././styles/SendInvitesDialog.scss";

interface IComponentProps {
  task: LearnerSyllabusTask;
  emailAddress?: string;
  locale?: string;
  appearance?:
    | "secondary"
    | "primary"
    | "outline"
    | "subtle"
    | "transparent"
    | undefined;
}

export const SendInviteDialog: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { task, emailAddress, appearance } = props;

  const payload: IPatchEventSessionsRequest[] = [
    { sessionRegId: task.sessionRegistrationId, sendInvite: true },
  ];

  const queryClient = useQueryClient();

  const resendSessions = useMutation({
    mutationFn: (payload: any) => patchLiveSessions(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Sessions"] });
    },
  });

  const sendInvite = () => {
    resendSessions.mutate(payload);
    ClarityHelper.setCustomTag(
      "SendInviteDialog",
      `ResendInviteClicked: ${task.syllabusTask.name}, learner: ${task.learnerId}`
    );
    ai.appInsights?.trackEvent({
      name: `ResendInviteClicked: ${task.syllabusTask.name}, learner: ${task.learnerId}`,
    });
  };

  return (
    <>
      <Dialog>
        <DialogTrigger>
          <Button
            appearance={appearance}
            aria-label={`${task.syllabusTask.name} Send Invite`}
          >
            <Text>Send Invite</Text>
          </Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Send Invite</DialogTitle>
            <DialogContent>
              <Text>
                Would you like us to send the calendar invitation to the email
                address <b>{emailAddress}</b>?
              </Text>
              <br />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="primary" onClick={sendInvite}>
                  Send
                </Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};
