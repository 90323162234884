import { Button, Link, Spinner, Text } from "@fluentui/react-components";
import { WidgetNoBorder } from "../lib/WidgetNoBorder";

import "../styles/Common.css";
import "../styles/HomeOverview.scss";
import { focusAreaOverviewVideosWidgetStyle } from "../lib/FocusAreaOverviewVideosWidget.styles";
import { BlendedLearningContext } from "../../internal/singletonContext";
import IconTips from "../components/IconTips";
import OverviewVideoCards from "../components/OverviewVideoCards";
import {Learner, Delivery } from "../../models";

interface ILiveSession {
  currentDelivery: Delivery;
  currentLearner: Learner;
}

export class FocusAreaOverviewVideosWidget extends WidgetNoBorder<ILiveSession> {
  protected async getData(): Promise<ILiveSession> {
    let blxpMainData =
      await BlendedLearningContext.getInstance().getBlendedLearning();    
    return {
      currentDelivery: blxpMainData.delivery,
      currentLearner: blxpMainData.learner,
    };
  }

  protected headerContent(): JSX.Element | undefined {
    return (
      <div>
        <h1 aria-label="Overview videos" className={focusAreaOverviewVideosWidgetStyle.header} tabIndex={0}>
            Overview videos
        </h1>
        <div className={focusAreaOverviewVideosWidgetStyle.subheader}>
          <Text className={focusAreaOverviewVideosWidgetStyle.headerText}>
            Watch any of our recorded videos where Microsoft trainers review material and post chalk talks that provide additional context to course material.
          </Text>

        </div>
      </div>
    );
  }

  protected bodyContent(): JSX.Element | undefined {
    return   (<OverviewVideoCards 
    learner={this.state.currentLearner}
    deliveryId={this.state.currentDelivery.id} />
      
    );
  }
  protected loadingContent(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label="Loading..." labelPosition="below" />
      </div>
    );
  }
}
