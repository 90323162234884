import React, { useEffect, useRef } from "react";
import { Image, mergeStyles, hiddenContentStyle } from "@fluentui/react";
import {
  ChartPerson24Regular,
  ShareScreenPerson24Regular,
  ChatBubblesQuestion24Regular,
} from "@fluentui/react-icons";

import { ProgressIndicator } from "./ProgressIndicator";

import "../styles/BlendedLearning.scss";
import "../styles/IntroDialog.scss";
import { useTeamsFx } from "@microsoft/teamsfx-react";

/**
 * IntroDialog3 component
 */
interface IComponentProps {
  stepNumber: number;
}

export const IntroDialogStep3: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { stepNumber } = props;
  const textToDisplay = "Live Sessions";
  const dialogReference = useRef<HTMLHeadingElement>(null);
  const screenReaderOnly = mergeStyles(hiddenContentStyle);
  const { themeString } = useTeamsFx();  
  const blIntroStep3 = require("../../assets/images/blIntroStep3.png");
  const blIntroStep3Dark = require("../../assets/images/blIntroStep3Dark.png");
  const imageSource = (themeString === 'dark') ? blIntroStep3Dark : blIntroStep3;

  useEffect(() => {
    if (dialogReference.current != null) {
      dialogReference.current.focus();
    }
  }, []);
  return (
    <>
      <label id="dialogLabelCongrats" className={screenReaderOnly}>
        {textToDisplay}
      </label>
      <div className="contentWrapper">
        <div className="content">
          <div className="dialogContentLeft">
            <ProgressIndicator stepNumber={stepNumber} />
            <h2 className="ms-fontWeight-bold" ref={dialogReference}>
              {textToDisplay}
            </h2>
            <div className="message">
              The live sessions are scheduled each week and include:
            </div>
            <ul className="list">
              <li className="listItem ms-Grid ms-Grid-row">
                <ChartPerson24Regular
                  aria-label="Chart person icon"
                  className="iconList ms-Grid-col ms-lg1"
                />
                <span>
                  <b>Chalk Talks</b> provide a deep dive into the material and
                  allow you to interact with trainers and peers.
                </span>
              </li>
              <li className="listItem ms-Grid ms-Grid-row">
                <ShareScreenPerson24Regular
                  aria-label="Share screen person icon"
                  className="iconList ms-Grid-col ms-lg1"
                />
                <span>
                  <b>Lab Sessions</b> offer additional support by explaining and
                  troubleshooting module exercises.
                </span>
              </li>
              <li className="listItem ms-Grid ms-Grid-row">
                <ChatBubblesQuestion24Regular
                  aria-label="Chat bubbles icon"
                  className="iconList ms-Grid-col ms-lg1"
                />
                <span>
                  <b>Office Hours</b> give you a chance to connect with trainers
                  who address common questions.
                </span>
              </li>
            </ul>
            <div className="message">
              Plan to attend a minimum of two Live Sessions: chalk talk, labs,
              or office hours.
            </div>
          </div>
          <div className="dialogContentRight">
            <Image
              src={imageSource}
              alt=""
              className="imageWrapper"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroDialogStep3;
