import React from "react";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";

import ".././styles/SendInvitesDialog.scss";

import { Button, Text } from "@fluentui/react-components";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";

interface IComponentProps {
  onContinue: () => void;
  count: number;
  emailAddress?: string;
  locale?: string;
  appearance?:
    | "secondary"
    | "primary"
    | "outline"
    | "subtle"
    | "transparent"
    | undefined;
}

export const SendInvitesDialog: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { onContinue, count, emailAddress, appearance } = props;

  const sendInvites = React.useCallback(() => {
    ai.appInsights?.trackEvent({ name: "Send Invites Dialog: Invites sent." });
    onContinue();
  }, [onContinue]);

  const sendInviteText = count === 1 ? "Send Invite" : "Send Invites";
  const calendarInvitationText =
    count === 1 ? "a calendar invitation" : "calendar invitations";

  return (
    <>
      <Dialog>
        <DialogTrigger>
          <Button appearance={appearance}>{sendInviteText}</Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{sendInviteText}</DialogTitle>
            <DialogContent>
              <Text>
                Would you like us to send {calendarInvitationText} to the email
                address <b>{emailAddress}</b>?
              </Text>
              <br />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="primary" onClick={sendInvites}>
                  Send
                </Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};
