import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { LearnerSyllabusTask } from '../../models';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, Link } from "@fluentui/react-components";
import { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BlendedLearningContext } from '../../internal/singletonContext';
import Helper from '../../common/helper';
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";
import moment from 'moment';
const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;

/**
 * PdfDocument component
 */
interface IComponentProps {
    tasks: LearnerSyllabusTask[];
    courseName: string | undefined;
    deliveryId: string | undefined;
}

export const PdfDocument: React.FunctionComponent<IComponentProps> = (
    props: IComponentProps
) => {
    const {
        tasks,
        courseName,
        deliveryId
    } = props;
    const [isLastWeek, setIsLastWeek] = useState(false);

    const getTimeLabel = (durationInMinutes: number) => {
        let minutes = durationInMinutes % 60;
        let hours = (durationInMinutes - minutes) / 60;

        const minuteUnit = minutes === 1 ? "minute" : "minutes";
        const hourUnit = hours === 1 ? "hour" : "hours";
        if (hours > 0) {
            return `${hours} ${hourUnit}`;
        }
        else if (minutes > 0) {
            return `${minutes} ${minuteUnit}`;
        }

    }


    const buildTableBody=(data: LearnerSyllabusTask[]) =>{
        var body = [];
        const COLUMN_HEADERS = ['Focus Area', 'Task', 'Flag', 'Complete', 'Time', 'Platform'];
        body.push(COLUMN_HEADERS.map(header => ({ text: header, fontSize: 10, bold: true })));
       


        data.forEach((row: LearnerSyllabusTask) => {
            const tasktitle = `${row.syllabusTask.taskOrderNumber} ${row.syllabusTask.name}`;
            const dataRow = [
                row.deliveryTopic.topics?.name,
                tasktitle,
                row.isTaskFlagged ? "Flagged" : "Not Flagged",
                row.isTaskCompleted ? "Completed" : "Not Completed",
                getTimeLabel((row.syllabusTask.durationInMinutes ?? 0) || 60),
                row.syllabusTask.platform.name
            ];
            body.push(dataRow);
        });

        return body;
    }   
    const downloadPdf = () => {

        ai.appInsights?.trackEvent({ name: `DownloadSyllabusPdf: DownloadSyllabusPdfClicked - ${deliveryId} - ${blxpData?.learner?.learnerId} - ${blxpData?.learner?.email}` });
        ClarityHelper.setCustomTag("DownloadSyllabusPdf", "DownloadSyllabusPdfClicked");

        const docDefinition: TDocumentDefinitions = {
            content: {
                table: {
                    headerRows: 1,                    
                    widths: ['10%','48%',  '10%', '10%', '10%', '12%'],
                    body: buildTableBody(tasks)
                },
                layout: {
                    hLineWidth: function(i, node) {
                        return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                      },
                      vLineWidth: function(i, node) {
                        return (i === 0 || i === node.table?.widths?.length) ? 0.0 : 0;
                      }
                }
            },
            defaultStyle: {
                fontSize: 10,
                columnGap: 20
            },
            pageOrientation: 'landscape',
            pageSize: "A4",
            header: {
                margin: [40, 20, 0, 0],
                table: {
                    body: [
                        [{
                            text: `Course : ${courseName}`,
                            fontSize:10,
                            bold:true,
                            alignment: "left"
                        }, {
                            text: `Delivery : ${deliveryId}`,
                            fontSize:10,
                            bold:true,
                            alignment: "left"

                        }]
                    ]
                } ,
                layout: 'noBorders'
            }
        };

        let date= (moment(new Date()).format("DD/MM/yyyy").toString()).replaceAll('/','');   
        pdfMake.createPdf(docDefinition).download(`${deliveryId}_${courseName} Syllabus_${date}.pdf`);
  
    }
    const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
        queryKey: ["BlxpData"],
        queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
    });

    useEffect(() => {
        if (blxpData != null) {
            let endDate = new Date(Date.parse(blxpData.delivery.endDate));
            let lastWeekstartDate = Helper.addDaysToDate(endDate, -7);
            let currentDate = new Date();
            if (currentDate >= lastWeekstartDate) {
                setIsLastWeek(true);
            }
        }
    }, [blxpDataLoading]);
    return (
        <>{(isLastWeek ?
            <Link onClick={downloadPdf} as='button' title='Syllabus PDF'  > Syllabus PDF</Link>
            :
            <Dialog modalType="alert">
                <DialogTrigger disableButtonEnhancement>
                    <Link as='button' title='Syllabus PDF'> Syllabus PDF</Link>
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogContent>
                            This task will be enabled at the conclusion of the course
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Close</Button>
                            </DialogTrigger>

                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        )}
        </>
    );
};
export default PdfDocument;