import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import { Link, tokens } from "@fluentui/react-components";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, shorthands } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { LearnerSyllabusTask } from '../../models';
import { TrackProps } from 'react-player/file';
import MediaAccessContext from '../../internal/mediaAccessContext';


/**
 * VideoDialog component
 */
interface IComponentProps {
    isOpen: boolean;
    onDismiss: () => void;
    onPlayVideo: (task: LearnerSyllabusTask) => void;
    task: LearnerSyllabusTask;
    showPlayButton: boolean;
}

const useStyles = makeStyles({
    dialogContent: {
        width: '80%',
        height: '95%',
        maxWidth: 'none',
        marginLeft: '10%',
    },
    dialogTitle: {
        width: 'max-content',
        display: 'inline-block',
    },
    dialogBody: {
        height: '95%',
        width: '100%',
    },
    dialogCloseButton: {
        float: 'right',
        marginTop: '-3.5%',
        width: '100%',
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '60%'
    },
    reactPlayer: {
        position: 'absolute',
        top: '0',
        left: '0',
    },
    playBtn: {       
        minWidth: "6em",
        height: "2em",
        ...shorthands.padding(".05em"),
        backgroundColor: tokens.colorBrandForegroundOnLightHover,
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '19px',
    }
})

export const VideoDialog: React.FunctionComponent<IComponentProps> = props => {
    const { onDismiss, task, onPlayVideo, showPlayButton } = props;
    const onCancel = () => {
        onDismiss();
    };
    const handleClick = () => {
    };
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            onPlayVideo(task);
        }
    }
    const classes = useStyles();
    const mediaAccessContext = useContext(MediaAccessContext);
    const getsubTitleUrl=()=>{
        const subtitle=task.syllabusTask.platformTaskURL.replace(".mp4",".vtt"); 
        return subtitle + mediaAccessContext?.token;          
    }
    return (
        <>
            <Dialog onOpenChange={(event, data) => !data.open && onPlayVideo(task)}>
                <DialogTrigger disableButtonEnhancement>
                    {(showPlayButton ?
                        <Button  appearance="primary" aria-label={`${task.syllabusTask.name}  Play video`}
                            className={classes.playBtn} shape="rounded" onClick={handleClick}>
                            Play video 
                        </Button> :
                        <Link onClick={handleClick} onKeyDown={handleKeyDown} role="button">{task.syllabusTask.name}</Link>
                    )}
                </DialogTrigger>
                <DialogSurface className={classes.dialogContent}>
                    <DialogTitle className={classes.dialogTitle} action={
                        <DialogTrigger action="close">
                            <Button className={classes.dialogCloseButton}
                                appearance="subtle"
                                aria-label="close"
                                onClick={onCancel}
                                icon={<Dismiss24Regular />}
                                tabIndex={0}
                            />
                        </DialogTrigger>
                    }>{task.syllabusTask.name}</DialogTitle>
                    <DialogBody className={classes.dialogBody}>
                        <DialogContent>
                            <div className={classes.playerWrapper}>
                                <ReactPlayer 
                                config={{ file: { attributes: {controlsList: 'nodownload',crossOrigin:"use-credentials" }, tracks: [{ kind: 'subtitles', src: `${getsubTitleUrl()}`, srcLang: 'en', default: true, label:'English'} as TrackProps] }}}
                                url={task.syllabusTask.platformTaskURL + mediaAccessContext?.token}
                                className={classes.reactPlayer}
                                playing={false}
                                controls={true}
                                width='100%'
                                height='100%'
                                tabIndex={1}
                                
                                />
                                
                                <iframe hidden sandbox=''  ></iframe>
                            </div>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

export default VideoDialog;