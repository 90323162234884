import * as axios from "axios";

import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";

import { applicationConfig } from "../common/settings";
import { HomeOverview } from "../models/homeOverview";
import { PagedResponse } from "../models/pagedResponse";
import { EndpointFunction, EndpointFunctionwithExtraParam, GetDefaultRequestConfig } from "../common/ServiceConfiguration";
import Logger from "../common/logger";
import { authentication } from "@microsoft/teams-js";
import { ILiveSessionStatus } from "../models/liveSessionStatus";

// const functionName = process.env.REACT_APP_API_BLENDEDLEARNINGSERVICES_URL + "/api/";
export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export async function getHomeOverview(): Promise<HomeOverview[]> {

  try {
    const token = await authentication.getAuthToken();
    const service = applicationConfig.services.apiServices;

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      service.baseUri,
      new BearerTokenAuthProvider(async () => token)
    );
    
    const requestConfig = await GetDefaultRequestConfig(service, false);
    const response = await apiClient.get<PagedResponse<HomeOverview>>(`${service.endpoints.HomeOverView}`, requestConfig);
    return response.data.items;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let apiErrorMsg = "";

      if (err?.response?.status === 404) {
        apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
      } else if (err.message === "Network Error") {
        apiErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        apiErrorMsg = err.message;
        if (err.response?.data?.error) {
          apiErrorMsg += ": " + err.response.data.error;
        }
      }

      Logger.LogError(apiErrorMsg + "\n" + err);
      return [];
    }
    Logger.LogError('', err);
    return [];
  }
}

export async function updateLearnerWelcomeTourState(deliveryId: string, learnerId: string) {

  try {
    const token = await authentication.getAuthToken();
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.PutLearnerDeliveryWelcomeTourStatus as EndpointFunctionwithExtraParam

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      service.baseUri,
      new BearerTokenAuthProvider(async () => token)
    );


    const requestConfig =await GetDefaultRequestConfig(service);
    const response = await apiClient.put(`${endpoint(deliveryId, learnerId)}`, undefined, requestConfig);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the api, `;
      } else if (err.message === "Network Error") {
        funcErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      Logger.LogError(funcErrorMsg + "\n" + err);
    }
    Logger.LogError('', err);
  }

}

export async function getLearnerWelcomeTourState(deliveryId: string, learnerId: string) {

  try {
    const token = await authentication.getAuthToken();
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.GetLearnerDeliveryWelcomeTourStatus as EndpointFunctionwithExtraParam

    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      service.baseUri,
      new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig =await GetDefaultRequestConfig(service);
    const response = await apiClient.get(`${endpoint(deliveryId, learnerId)}`, requestConfig);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let funcErrorMsg = "";

      if (err?.response?.status === 404) {
        funcErrorMsg = `There may be a problem with the api, `;
      } else if (err.message === "Network Error") {
        funcErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        funcErrorMsg = err.message;
        if (err.response?.data?.error) {
          funcErrorMsg += ": " + err.response.data.error;
        }
      }

      Logger.LogError(funcErrorMsg + "\n" + err);
    }
    Logger.LogError('', err);
  }

}

export async function getLearnerSyllabusTasks(learnerId: string, deliveryId: string) {

  try {
    const token = await authentication.getAuthToken();
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.LearnerSyllabusTasks as EndpointFunctionwithExtraParam;
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      service.baseUri,
      new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig =await GetDefaultRequestConfig(service);
    const response = await apiClient.get(`${endpoint(learnerId, deliveryId)}`, requestConfig);
    return response.data.items;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let apiErrorMsg = "";

      if (err?.response?.status === 404) {
        apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
      } else if (err.message === "Network Error") {
        apiErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        apiErrorMsg = err.message;
        if (err.response?.data?.error) {
          apiErrorMsg += ": " + err.response.data.error;
        }
      }

      Logger.LogError(apiErrorMsg + "\n" + err);
    }
    Logger.LogError('', err);
  }
}

export async function getLearnerSyllabusTaskLabUrl(learnerSyllabusTaskId: string, locale: string) {

  try {
    const token = await authentication.getAuthToken();
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.getLearnerSyllabusTaskLabUrl as EndpointFunctionwithExtraParam;
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      service.baseUri,
      new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig =await GetDefaultRequestConfig(service, false);
    const response = await apiClient.get(`${endpoint(learnerSyllabusTaskId, locale)}`, requestConfig);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let apiErrorMsg = "";

      if (err?.response?.status === 404) {
        apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
      } else if (err.message === "Network Error") {
        apiErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        apiErrorMsg = err.message;
        if (err.response?.data?.error) {
          apiErrorMsg += ": " + err.response.data.error;
        }
      }

      Logger.LogError(apiErrorMsg + "\n" + err);
    }
    Logger.LogError('', err);
  }
}

export async function getTrainerClassViewUrl(deliveryId: string) {

  try {
    const token = await authentication.getAuthToken();
    const service = applicationConfig.services.apiServices;
    const endpoint = service.endpoints.getTrainerClassViewUrl as EndpointFunction;
    // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
    const apiClient = createApiClient(
      service.baseUri,
      new BearerTokenAuthProvider(async () => token)
    );

    const requestConfig =await GetDefaultRequestConfig(service, false);
    const response = await apiClient.get(`${endpoint(deliveryId)}`, requestConfig);
    return response.data;
  } catch (err: unknown) {
    if (axios.default.isAxiosError(err)) {
      let apiErrorMsg = "";

      if (err?.response?.status === 404) {
        apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
      } else if (err.message === "Network Error") {
        apiErrorMsg =
          "Cannot call Azure Function due to network error, please check your network connection status and ";
        if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
          apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
        } else {
          apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
        }
      } else {
        apiErrorMsg = err.message;
        if (err.response?.data?.error) {
          apiErrorMsg += ": " + err.response.data.error;
        }
      }

      Logger.LogError(apiErrorMsg + "\n" + err);
    }
    Logger.LogError('', err);
  }
}


export async function getLiveSessionStatus(learnerId: string, deliveryId: string): Promise<ILiveSessionStatus> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.getLiveSession as EndpointFunctionwithExtraParam

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
       const requestConfig =await GetDefaultRequestConfig(service);
       const response = await apiClient.get<ILiveSessionStatus>(`${endpoint(learnerId,deliveryId)}`, requestConfig);
       return response.data;       
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as ILiveSessionStatus;
        }
        Logger.LogError('', err);
        return {} as ILiveSessionStatus;
    }
}


export async function saveLiveSessionStatus(learnerId: string, deliveryId: string,liveSessionStatus: ILiveSessionStatus) {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.saveLiveSession as EndpointFunctionwithExtraParam

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
        const requestConfig =await GetDefaultRequestConfig(service);
       const response = await apiClient.put(`${endpoint(learnerId,deliveryId)}`, liveSessionStatus, requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as ILiveSessionStatus;
        }
        Logger.LogError('', err);
        return {} as ILiveSessionStatus;
    }
}