import React, { useEffect, useState } from "react";
import { Button, Text, makeStyles, tokens } from "@fluentui/react-components";

import ".././styles/SendInvitesDialog.scss";
import { LearnerSyllabusTask } from "../../models";
import { ai } from "../../internal/ApplicationInsightsProvider/ApplicationInsightsService";
import { ClarityHelper } from "../../internal/ClarityHelper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateLearnerSyllabusTaskCompletedState } from "../../services/syllabusService";
import { BlendedLearningContext } from "../../internal/singletonContext";
import { convertToLocalTimeZone } from "../../common/dateUtils";

interface IComponentProps {
  item: LearnerSyllabusTask | any;
}

const useStyles = makeStyles({
  teamsButton: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralStrokeOnBrand2,
  },
});

export const JoinSessionButton: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const { item } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { data: blxpData, isLoading: blxpDataLoading } = useQuery({
    queryKey: ["BlxpData"],
    queryFn: () => BlendedLearningContext.getInstance().getBlendedLearning(),
  });

  const endTime = convertToLocalTimeZone(
    new Date(Date.parse(item.taskEndDateTime))
  ).getTime();
  const startTime = convertToLocalTimeZone(
    new Date(Date.parse(item.taskStartDateTime))
  ).getTime();
  const currentTime = Date.now();

  const [showButton, setShowButton] = useState(false);

  const timeToHide = endTime - currentTime;

  useEffect(() => {
    if (currentTime >= startTime - 1800000 && currentTime < endTime) {
      setShowButton(true);
    }

    setInterval(() => {
      setShowButton(false);
    }, timeToHide);
  }, []);

  const handleJoinSessionClick = () => {
    ai.appInsights?.trackEvent({
      name: `JoinSessionButtonClicked - ${item.syllabusTask.name}`,
    });
    ClarityHelper.setCustomTag(
      "JoinSessionButtonClick",
      `${item.syllabusTask.name}`
    );
    window.open(item.taskURL);
    if (!item.isTaskCompleted) {
      onCompletedClick();
    }
  };
  const taskCompletionMutation = useMutation({
    mutationFn: async () => {
      await updateLearnerSyllabusTaskCompletedState(
        item.id,
        blxpData?.learner?.email ?? ""
      );
    },
    onMutate: () => {
      item.isTaskCompleted = !item.isTaskCompleted;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["UserTasksData"]);
    },
    onSettled: () => {},
  });

  const onCompletedClick = async () => {
    ai.appInsights?.trackEvent({
      name: "Join Session: Task is competed (mouse click).",
    });
    ClarityHelper.setCustomTag("CompleteTask", `${item.syllabusTask}`);
    await taskCompletionMutation.mutateAsync();
  };
  return (
    <>
      {showButton && (
        <Button
          appearance="primary"
          className={classes.teamsButton}
          onClick={handleJoinSessionClick}
          aria-label="Join Session in a new tab"
        >
          <Text>Join Session</Text>
        </Button>
      )}
    </>
  );
};

export default JoinSessionButton;
