import React from "react";
import { app, pages } from "@microsoft/teams-js";
import { Button } from "@fluentui/react-components";

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
const TabConfig: React.FunctionComponent = () => {
  app.notifyAppLoaded();
    // Initialize the Microsoft Teams SDK
    // app.initialize().then(() => {
    //   /**
    //    * When the user clicks "Save", save the url for your configured tab.
    //    * This allows for the addition of query string parameters based on
    //    * the settings selected by the user.
    //    */
    //   pages.config.registerOnSaveHandler((saveEvent) => {
    //     const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    //     pages.config
    //       .setConfig({
    //         suggestedDisplayName: "My Tab",
    //         entityId: "Test",
    //         contentUrl: baseUrl + "/#/tab",
    //         websiteUrl: baseUrl + "/#/tab",
    //       })
    //       .then(() => {
    //         saveEvent.notifySuccess();
    //       });
    //   });

    //   /**
    //    * After verifying that the settings for your tab are correctly
    //    * filled in by the user you need to set the state of the dialog
    //    * to be valid.  This will enable the save button in the configuration
    //    * dialog.
    //    */
    //   pages.config.setValidityState(true);
    // });

    const onHomeClick = () => {

      app.initialize().then(() => {
        /**
         * When the user clicks "Save", save the url for your configured tab.
         * This allows for the addition of query string parameters based on
         * the settings selected by the user.
         */
        pages.config.registerOnSaveHandler((saveEvent) => {
          const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
          pages.config
            .setConfig({
              suggestedDisplayName: "Home",
              entityId: "Test",
              contentUrl: baseUrl + "/#/tab?team={teamId}&channel={channelId}&{locale}"
            })
            .then(() => {
              saveEvent.notifySuccess();
            });
        });

        /**
         * After verifying that the settings for your tab are correctly
         * filled in by the user you need to set the state of the dialog
         * to be valid.  This will enable the save button in the configuration
         * dialog.
         */
        pages.config.setValidityState(true);
      });
    };

    const onFocusAreaClick = () => {
      app.initialize().then(() => {
        /**
         * When the user clicks "Save", save the url for your configured tab.
         * This allows for the addition of query string parameters based on
         * the settings selected by the user.
         */
        pages.config.registerOnSaveHandler((saveEvent) => {
          const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
          pages.config
            .setConfig({
              suggestedDisplayName: "Focus Area Hub",
              entityId: "Test",
              contentUrl: baseUrl + "/#/focusarea?team={teamId}&channel={channelId}&{locale}"
            })
            .then(() => {
              saveEvent.notifySuccess();
            });
        });

        /**
         * After verifying that the settings for your tab are correctly
         * filled in by the user you need to set the state of the dialog
         * to be valid.  This will enable the save button in the configuration
         * dialog.
         */
        pages.config.setValidityState(true);
      });
    }

    const onTrainerViewDashboard = () => {
      app.initialize().then(() => {
        /**
         * When the user clicks "Save", save the url for your configured tab.
         * This allows for the addition of query string parameters based on
         * the settings selected by the user.
         */
        pages.config.registerOnSaveHandler((saveEvent) => {
          const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
          pages.config
            .setConfig({
              suggestedDisplayName: "Trainer Control Dashboard",
              entityId: "Test",
              contentUrl: baseUrl + "/#/trainer?team={teamId}&channel={channelId}&{locale}"
            })
            .then(() => {
              saveEvent.notifySuccess();
            });
        });

        /**
         * After verifying that the settings for your tab are correctly
         * filled in by the user you need to set the state of the dialog
         * to be valid.  This will enable the save button in the configuration
         * dialog.
         */
        pages.config.setValidityState(true);
      });
    }

    return (
      <div>
        <h1>Tab Configuration</h1>
        <div>
          This is where you will add your tab configuration options the user can
          choose when the tab is added to your team/group chat.
        </div>
        <Button onClick={onHomeClick}>Home</Button>
        <Button onClick={onFocusAreaClick}>Focus Area Hub</Button>
        <Button onClick={onTrainerViewDashboard}>Trainer Control Dashboard</Button>
      </div>
    );
  
}

export default TabConfig;
