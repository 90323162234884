

import React from "react";
import {
  Spinner,
  Text,
} from "@fluentui/react-components";
import { Diversity24Regular } from "@fluentui/react-icons";
import { ITooltipHostStyles } from "@fluentui/react";

import { Delivery, Learner } from "../../models";
import { yourProgressWidgetStyle } from "../lib/YourProgressWidget.styles";
import { WidgetNoBorder } from "../lib/WidgetNoBorder";
import YourProgress from "../components/YourProgress";

import "../styles/Common.css";
import "../styles/Task.css";
import { BlendedLearningContext } from "../../internal/singletonContext";
import IconTips from "../components/IconTips";

interface ISyllabus {
  currentDelivery: Delivery,
  currentLearner: Learner
}

export class YourProgressWidget extends WidgetNoBorder<ISyllabus> {
  constructor(props:any){
    super(props);
    
  }
  protected async getData(): Promise<ISyllabus> {  
    const blxpMainData = await BlendedLearningContext.getInstance().getBlendedLearning();
    return {
      currentDelivery: blxpMainData.delivery,
      currentLearner: blxpMainData.learner
    } as ISyllabus;
  }

  inlineBlockStyle: Partial<ITooltipHostStyles> = {
    root: {
      display: 'inline-block'
    }
  };

  infoText = <Text>
     This progress section is a summary of the tasks within each focus area. Completed tasks in the syllabus show as shaded dark boxes and allow you to plan for the remaining training. Boxes marked with a dot are tasks you have flagged.
  </Text>;

  protected loadingContent(): JSX.Element | undefined {
    return (
      <div className="loading-layout">
        <Spinner label="Loading..." labelPosition="below" />
      </div>
    );
  }

  protected headerContent(): JSX.Element | undefined {
    return (
      <div className={yourProgressWidgetStyle.header}>
        <div>
        <h1 className="headerSmall" aria-label="Your progress">
            <Text className={yourProgressWidgetStyle.headerText}>Your progress</Text>
            <IconTips iconTitle="Your progress" text={this.infoText} icon="question" ariaTextId="yourProgressText"/>
          </h1>
        </div>
      </div>
    );
  }

  protected bodyContent(): JSX.Element | undefined {
    return(
      <div className="yourProgressTableWrapper">
      <YourProgress learner={this.state.currentLearner} deliveryId={this.state.currentDelivery.id}
      isShowFocusArea={false} 
      channelId={this.props.currentFocusArea}
      isShowHeader={this.props.isShowHeader}
      isShowRightColumn={this.props.isShowRightColumn}/>
      </div>
    );
  }
}
