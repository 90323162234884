import { mergeStyleSets } from "@fluentui/react";

export const focusAreaOverviewVideosWidgetStyle = mergeStyleSets({
  root: {
    background: "none",
    border: "none",
  },
  header: {
    wrap: "wrap",
    height: "max-content",
    fontWeight: "700 !important",
    lineHeight: "20px !important",
    fontSize: "14px !important",
  },
  subheader: {
    display: "flex",
    gap: "1em",
    alignContent: "space-between",
    marginBottom: "2em",
  },
  headerText: {
    display: "flex",
  },
  bodyNoMoreVideos: {
    height: "10em",
    marginTop: "7.5em",
  },
  link:{
    color:"#7A55BF !important"
  }
});
