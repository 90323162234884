import { CSSProperties } from "react";

import { Dashboard } from "../lib/Dashboard";
import { SyllabusWidget } from "../widgets/SyllabusWidget";
import { BackToTop } from "../components/BackToTop";
import { FocusAreaYourProgressWidget } from "../widgets/FocusAreaYourProgressWidget";
import { FocusAreaOverviewWidget } from "../widgets/FocusAreaOverviewWidget";
import { FocusAreaLiveSessionsWidget } from "../widgets/FocusAreaLiveSessionsWidget";
import { FocusAreaOverviewVideosWidget } from "../widgets/FocusAreaOverviewVideosWidget";
import "../styles/MyDashboard.css";


export default class FocusArea extends Dashboard {
  protected dashboardLayout(): JSX.Element | undefined {
    return (
      <>
       
        <FocusAreaOverviewWidget />
        <FocusAreaYourProgressWidget />
        <div className={`${!this.state.isMobile ? "oneRow" : ""}`}>
          <SyllabusWidget isShowSelectedFousArea={true} />
          <FocusAreaOverviewVideosWidget />
          <FocusAreaLiveSessionsWidget />
        </div>
        <BackToTop />
      </>
    );
  }

  protected columnWidths(): string | undefined {
    return "4fr 7fr";
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  protected customiseDashboardStyle(): CSSProperties | undefined {
    return this.state.showLogin === false
      ? {
          marginTop: "5%",
        }
      : {
          padding: 0,
          marginTop: 0,
        };
  }
}
