import * as React from 'react';

import '../styles/ProgressIndicator.scss';

/**
 * ProgressIndicator component
 */

interface IComponentProps {
  stepNumber: number;
}

export const ProgressIndicator: React.FunctionComponent<IComponentProps> = props => {
  const { stepNumber } = props;
  return (
    <>
      <ul className="progressIndicator">
        <li className={`indicator ${stepNumber === 1 ? 'activeView' : 'inactiveView'}`} key={1}></li>
        <li className={`indicator ${stepNumber === 2 ? 'activeView' : 'inactiveView'}`} key={2}></li>
        <li className={`indicator ${stepNumber === 3 ? 'activeView' : 'inactiveView'}`} key={3}></li>
        <li className={`indicator ${stepNumber === 4 ? 'activeView' : 'inactiveView'}`} key={4}></li>
      </ul>
      <span className="labelIndicator">{stepNumber}/4</span>
    </>
  );
};

export default ProgressIndicator;
