import * as axios from "axios";

import { ApiKeyLocation, ApiKeyProvider, BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";

import { TeamsUserCredentialContext } from "../internal/singletonContext";
import { applicationConfig } from "../common/settings";
import { PagedResponse } from "../models/pagedResponse";
import { EndpointFunction, EndpointFunctionWithOptionalParam, EndpointFunctionwithExtraParam, EndpointFunctionwithThreeParam, GetDefaultRequestConfig, IServiceConfiguration } from "../common/ServiceConfiguration";
import { Delivery, IGetLiveSessionsResponse, LiveSession, IGetLiveSessionsRequest, Topic, IPatchEventSessionsRequest, IPatchEventSessionsResponse, IDeliveryTopic } from "../models";
import { AxiosRequestConfig } from "axios";
import Logger from "../common/logger";
import { RequestMethod } from "../common/RequestMethod";
import { authentication } from "@microsoft/teams-js";
import { ILearnerTeamState } from "../models/learnerTeamState";


export async function getDeliveryDetails(deliveryId: string): Promise<Delivery> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.Delivery as EndpointFunction
        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.get<Delivery>(`${endpoint(deliveryId)}`,requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy to the cloud") first before running this App`;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(funcErrorMsg + "\n" + err);
            return {} as Delivery;
        }
        Logger.LogError('', err);
        return {} as Delivery;
    }
}


export async function getDeliveryTopics(deliveryId: string) {
   
    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.Delivery as EndpointFunction
        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.get<Delivery>(`${endpoint(deliveryId)}`,requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `There may be a problem with the api, `;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(funcErrorMsg + "\n" + err);
        }
        Logger.LogError('', err);
    }

}

export async function patchLiveSessions(params: IPatchEventSessionsRequest[]): Promise<IPatchEventSessionsResponse> {

     
    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.patchSessions as string
        const usestaticBearerToken = false;
        const bearerToken = '';
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
        const axiosConfig = {headers: { 'Ocp-Apim-Subscription-Key': service.apiKey }}
        const response = await apiClient.patch<IPatchEventSessionsResponse>(`${endpoint}`, params, axiosConfig);
        return response.data;


    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `There was an error getting the live sessions from the api`;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(funcErrorMsg + "\n" + err);
            return {} as IPatchEventSessionsResponse;
        }
        Logger.LogError('',err);
        return {} as IPatchEventSessionsResponse;
    }
  }

export async function getLiveSessions(deliveryId: string, learnerId: string,tpId:string): Promise<LiveSession[]> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.Sessions as EndpointFunctionwithThreeParam;
        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header

        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        
        const response = await apiClient.get<IGetLiveSessionsResponse>(`${endpoint(deliveryId, learnerId,tpId)}`, requestConfig);

        return response?.data.sessions;

    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy to the cloud") first before running this App`;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(funcErrorMsg + "\n" + err);
            return [];
        }
        Logger.LogError('', err);
        return [];
    }
}



export async function getDeliveryTeamsId(groupId: string): Promise<Delivery> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.teamsSessions as EndpointFunction

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.get<Delivery>(`${endpoint(groupId)}`,requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as Delivery;
        }
        Logger.LogError('', err);
        return {} as Delivery;
    }
}


export async function getTopicsByDeliveryId(deliveryId: string, teamChannelId?: string): Promise<IDeliveryTopic[]> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.DeliveryTopic as EndpointFunctionWithOptionalParam

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
        const requestConfig = await GetDefaultRequestConfig(service);

        const response = await apiClient.get<PagedResponse<IDeliveryTopic>>(`${endpoint(deliveryId,teamChannelId )}`, requestConfig);
        return response.data.items;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return [];
        }
        Logger.LogError('', err);
        return [];
    }
}


export async function getLearnerTeamState(deliveryId: string,learnerId:string): Promise<ILearnerTeamState> {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.getLearnerTeamState as EndpointFunctionwithExtraParam

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
       const requestConfig = await GetDefaultRequestConfig(service);
       const response = await apiClient.get<ILearnerTeamState>(`${endpoint(learnerId,deliveryId)}`, requestConfig);
       return response.data;       
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as ILearnerTeamState;
        }
        Logger.LogError('', err);
        return {} as ILearnerTeamState;
    }
}


export async function saveLearnerTeamState(deliveryId: string,learnerId:string,state : ILearnerTeamState) {

    try {
        const token = await authentication.getAuthToken();
        const service = applicationConfig.services.apiServices;
        const endpoint = service.endpoints.saveLearnerTeamState as EndpointFunctionwithExtraParam

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
        const requestConfig = await GetDefaultRequestConfig(service);
       const response = await apiClient.put(`${endpoint(learnerId,deliveryId)}`, state, requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as ILearnerTeamState;
        }
        Logger.LogError('', err);
        return {} as ILearnerTeamState;
    }
}
