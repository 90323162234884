


export default class Logger {



    public static LogError(message?: string, error?: any) { 
        console.log(message, error);

    }
    public static LogInfo(message: string, error?: any) { }
    public static LogWarning(message: string, error?: any) { }
    public static LogTrace(message: string, error?: any) { }
    public static LogCritical(message: string, error?: any) { }
    public static LogDebug(message: string, error?: any) { }

}